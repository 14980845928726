import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Professional } from '../../types/Professional'

// ========================|| CONTEXT - PROFESSIONAL ||======================== //

interface ProfessionalContextType {
  professional: Professional
  setProfessional: Dispatch<SetStateAction<Professional | undefined>>
}

const ProfessionalContext = createContext<ProfessionalContextType>({} as ProfessionalContextType)

export default ProfessionalContext