import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ClientDeleteDialog from '../ClientDeleteDialog/ClientDeleteDialog'
import { Client } from '../../../../types/Client'

// ========================|| CLIENT - DELETE BUTTON ||======================== //

interface Props {
  client: Client
}

export default function ClientDeleteIconButton({ client }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Delete')}>
        <span>
          <IconButton color='error' size='large' onClick={handleOpen} disabled={!!client.user}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
      <ClientDeleteDialog open={open} client={client} onClose={handleClose} />
    </>
  )
}
