import { useContext } from 'react'

// third-party
import { useTranslation } from 'react-i18next'

// material-ui
import { useNavigate } from 'react-router-dom'
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// project imports
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import { isInactive } from '../../../../utils/subscription'

// ========================|| BOOKING - CREATE BUTTON ||======================== //

export default function BookingCreateButton() {
  // hooks
  const companyId = useCompanyId()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { subscription } = useContext(OptionalSubscriptionContext)

  const handleNavigate = () => {
    navigate(`/companies/${companyId}/bookings/new`)
  }

  const tooltipTitle = () => {
    if (isInactive(subscription)) {
      return 'An active subscription is required to create bookings.'
    }

    return 'Create Booking'
  }

  const isDisabled = isInactive(subscription)

  return (
    <Tooltip title={t(tooltipTitle())}>
      <span>
        <Fab
          color='primary'
          aria-label='Create booking'
          sx={{
            width: '32px',
            height: '32px',
            minHeight: '32px',
            boxShadow: 'none',
          }}
          disabled={isDisabled}
          onClick={handleNavigate}
        >
          <AddIcon fontSize='small' />
        </Fab>
      </span>
    </Tooltip>
  )
}
