// material-ui
import { IconButton, Tooltip } from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - SEE BUTTON ||======================== //

interface Props {
  company: UserCompany
}

export default function UserCompanyViewIconButton({ company }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/companies/${company.id}/bookings`)
  }

  return (
    <Tooltip title={t('View')}>
      <IconButton aria-label='View company' color='primary' size='large' onClick={handleNavigate}>
        <VisibilityTwoToneIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
