// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// third-party
import { useTranslation } from 'react-i18next'
import { DialogProps } from '@mui/material/Dialog'
import { Booking, BookingStatus } from '../../../../types/Booking'
import dayjs from 'dayjs'

// project imports
import { locale } from '../../../../utils/locale'

// ==============================|| DIALOG - WHATSAPP ||============================== //

interface Props extends DialogProps {
  booking: Booking
  onClose: any
}

export default function WhatsAppDialog({ booking, onClose, ...dialogProps }: Props) {
  const { i18n, t } = useTranslation()

  const text = `Hola ${booking.client.name}, tenés el siguiente turno:%0a%0aServicio: ${booking.service.name}%0aProfesional: ${
    booking.professional.name
  }%0aFecha: ${dayjs(booking.date).locale(locale(i18n.language)).tz(booking.branch.address.timezone).calendar()}%0aUbicación: ${
    booking.branch.address.description
  }`

  const handleSendReminder = () => {
    window.open(`https://wa.me/${formatPhone(booking.client.user!.phone)}?text=${text}`)
    onClose()
  }

  const handleStartConversation = () => {
    window.open(`https://wa.me/${formatPhone(booking.client.user!.phone)}`)
    onClose()
  }

  const formatPhone = (phone: string) => {
    return phone.replace(/[^\d+]/g, '')
  }

  const isDisabled = booking.status === BookingStatus.CANCELED || new Date(booking.date).getTime() < new Date().getTime()

  return (
    <Dialog aria-labelledby='whatsapp-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='whatsapp-dialog'>{t('You will be redirected')}</DialogTitle>
      <IconButton aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 12, top: 24 }}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText id='whatsapp-dialog-description'>{t('Start a WhatsApp conversation or just send a reminder.')}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 24px' }}>
        <Button
          variant='outlined'
          disableElevation
          onClick={handleStartConversation}
          sx={{
            width: '100%',
            backgroundColor: '#ffffff',
            color: '#075e54',
            borderColor: '#075e5480',
            '&:hover': {
              backgroundColor: '#075e540a',
              color: '#075e54',
              borderColor: '#075e54',
            },
          }}
        >
          {t('Start conversation')}
        </Button>
        <Button
          variant='contained'
          disableElevation
          onClick={handleSendReminder}
          disabled={isDisabled}
          sx={{
            width: '100%',
            backgroundColor: '#25d366',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#25d366',
              color: '#ffffff',
              opacity: 0.8,
            },
          }}
        >
          {t('Send reminder')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
