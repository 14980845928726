import { useEffect, useState } from 'react'

// third-party
import dayjs from 'dayjs'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserBookingApi from '../../api/booking/UserBookingApi'
import { Booking, BookingParams, BookingStatus } from '../../types/Booking'
import { ApiError } from '../../types/ApiError'
import { Page, DEFAULT_PAGE } from '../../types/Page'

// apis
const userBookingApi = new UserBookingApi()

// ========================|| HOOK - USER BOOKINGS ||======================== //

export default function useUserBookings() {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [bookings, setBookings] = useState<Page<Booking>>(DEFAULT_PAGE)
  const [params, setParams] = useState<BookingParams>({
    startDate: dayjs(),
    status: [BookingStatus.CONFIRMED, BookingStatus.PENDING],
    professionals: [],
    sort: 'date,asc',
    page: 0,
    size: 5,
  })

  useEffect(() => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      userBookingApi
        .findAll(token, params)
        .then(data => setBookings(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }, [params])

  return { firstLoading, loading, error, bookings, params, setBookings, setParams }
}