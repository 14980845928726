import { ReactNode } from 'react'

// project imports
import ProfessionalsContext from './ProfessionalsContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useProfessionals from '../../hooks/professional/useProfessionals'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - PROFESSIONALS ||======================== //

export default function ProfessionalsProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { firstLoading, loading, error, professionals, params, setProfessionals, setParams } = useProfessionals(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (firstLoading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <ProfessionalsContext.Provider value={{ loading, error, professionals, params, setProfessionals, setParams }}>{children}</ProfessionalsContext.Provider>
}