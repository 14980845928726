// material-ui
import { Grid2 as Grid, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import TimeAutocomplete from '../../../../../ui-components/extended/autocomplete/TimeAutocomplete/TimeAutocomplete'
import ServiceProfessionalDatePicker from '../../../../../ui-components/extended/datepicker/ServiceProfessionalDatePicker'
import { locale } from '../../../../../utils/locale'
import { findTimezoneLabel } from '../../../../../utils/timezone'
import { BookingCreateForm } from '../../../../../types/Booking'

// ========================|| BOOKING - FORM - SELECT DATE ||======================== //

export default function SelectDateStep() {
  const { i18n, t } = useTranslation()
  const { control, watch } = useFormContext<BookingCreateForm>()

  const service = watch('service')
  const professional = watch('professional')
  const date = watch('date')

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 6 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale(i18n.language)}>
          <Controller
            name='date'
            control={control}
            rules={{ required: 'This field is required.' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <ServiceProfessionalDatePicker
                service={service}
                professional={professional}
                value={value}
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error ? t(error.message as string) : null,
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Controller
          name='time'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TimeAutocomplete
              service={service}
              professional={professional}
              date={date}
              value={value}
              onChange={(e, time) => onChange(time)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('Hour')}
                  error={!!error}
                  helperText={error ? t(error.message as string) : findTimezoneLabel(professional!.branch.address.timezone)}
                />
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
