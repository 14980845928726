// material-ui
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone'

// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useServiceId from '../../../../hooks/service/useServiceId'
import Tabs from '../../../../ui-components/extended/tabs/Tabs'
import { Tab } from '../../../../ui-components/extended/tabs/Tab'

// ========================|| BRANCH - TABS ||======================== //

export default function ServiceTabs() {
  // hooks
  const companyId = useCompanyId()
  const serviceId = useServiceId()

  const URL_SERVICE = `/companies/${companyId}/services/${serviceId}`
  const URL_PROFESSIONALS = `/companies/${companyId}/services/${serviceId}/professionals`

  const tabs: Tab[] = [
    {
      url: URL_SERVICE,
      title: 'Details',
      icon: <AccountCircleTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_PROFESSIONALS,
      title: 'Professionals',
      icon: <PeopleAltTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
  ]

  return <Tabs tabs={tabs} />
}
