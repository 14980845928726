// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { ProfessionalUser } from '../../types/ProfessionalUser'
import { UserProfessionalParams } from '../../types/UserProfessional'
import { UserProfessionalCreateDto } from './dto/UserProfessionalCreateDto'
import { UserProfessionalUpdateDto } from './dto/UserProfessionalUpdateDto'

// ========================|| API - PROFESSIONAL USER ||======================== //

export default class ProfessionalUserApi {
  findAll(token: string, professionalId: string, params: UserProfessionalParams): Promise<Page<ProfessionalUser>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/professionals/${professionalId}/users`, config)
  }

  create(token: string, professionalId: string, user: UserProfessionalCreateDto): Promise<ProfessionalUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/professionals/${professionalId}/users`, user, config)
  }

  update(token: string, professionalId: string, userId: string, user: UserProfessionalUpdateDto): Promise<ProfessionalUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/professionals/${professionalId}/users/${userId}`, user, config)
  }

  delete(token: string, professionalId: string, userId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/professionals/${professionalId}/users/${userId}`, config)
  }
}
