import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../context/usercompany/UserCompanyContext'
import ProfessionalContext from '../../context/professional/ProfessionalContext'

// ========================|| HOOK - PROFESSIONAL BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userCompany } = useContext(UserCompanyContext)
  const { professional } = useContext(ProfessionalContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const professionalsUrl = { title: t('Professionals'), url: `/companies/${userCompany.id}/professionals` }
  const professionalUrl = { title: professional.name, url: `/companies/${userCompany.id}/professionals/${professional.id}` }

  const businessHours = { title: t('Business Hours') }
  const services = { title: t('Services') }
  const users = { title: t('Administrators') }
  const professionalName = { title: professional.name }

  switch (pathname.split('/').at(-1)) {
    case 'business-hours': {
      return [professionalsUrl, professionalUrl, businessHours]
    }
    case 'services': {
      return [professionalsUrl, professionalUrl, services]
    }
    case 'users': {
      return [professionalsUrl, professionalUrl, users]
    }
    default: {
      return [professionalsUrl, professionalName]
    }
  }
}

export default useBreadcrumbs
