// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ServiceProfessionalContext from '../../../../context/serviceprofessional/ServiceProfessionalContext'
import useServiceId from '../../../../hooks/service/useServiceId'
import useServiceProfessionals from '../../../../hooks/serviceprofessional/useServiceProfessionals'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import Loader from '../../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../../ui-components/extended/alert/ApiErrorAlert'
import ServiceProfessionalTable from '../ServiceProfessionalTable/ServiceProfessionalTable'

// ========================|| PROFESSIONAL - TABS - SERVICES ||======================== //

export default function ServiceProfessionalTab() {
  const serviceId = useServiceId()
  const { t } = useTranslation()
  const { loading, error, ...state } = useServiceProfessionals(serviceId)

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return (
    <ServiceProfessionalContext.Provider value={state}>
      <SubCard title={t('Professionals')} content={false}>
        <ServiceProfessionalTable />
      </SubCard>
    </ServiceProfessionalContext.Provider>
  )
}
