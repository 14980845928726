// material-ui
import { Button } from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - VIEW BUTTON ||======================== //

interface Props {
  userCompany: UserCompany
}

export default function UserCompanyViewButton({ userCompany }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/companies/${userCompany.id}/bookings`)
  }

  return (
    <Button variant='outlined' size='small' fullWidth startIcon={<VisibilityTwoToneIcon fontSize='small' />} onClick={handleClick}>
      {t('View')}
    </Button>
  )
}
