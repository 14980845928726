// material-ui
import { Button } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { Professional } from '../../../../types/Professional'

// ========================|| PROFESSIONAL - EDIT BUTTON ||======================== //

interface Props {
  professional: Professional
}

export default function ProfessionalEditButton({ professional }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${professional.id}`)
  }

  return (
    <Button variant='outlined' color='secondary' size='small' fullWidth startIcon={<EditIcon fontSize='small' />} onClick={handleClick}>
      {t('Edit')}
    </Button>
  )
}
