import { useContext, useState } from 'react'

// material-ui
import { Box } from '@mui/material'

// third-party
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
// project imports
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import BookingStepper from '../BookingStepper/BookingStepper'
import CompanyBookingApi from '../../../../api/booking/CompanyBookingApi'
import { BookingCreateForm } from '../../../../types/Booking'
import { ApiError } from '../../../../types/ApiError'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { EXCEPTION_BOOKING_NOT_AVAILABLE } from '../../../../api/exceptions/exceptions'

// ========================|| BOOKING - FORM ||======================== //

const bookingApi = new CompanyBookingApi()

export default function BookingForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(CompanyStatsContext)
  const [loading, setLoading] = useState(false)

  const methods = useForm<BookingCreateForm>({
    mode: 'onChange',
    defaultValues: {
      service: null,
      professional: null,
      client: null,
      date: null,
      time: null,
    },
  })

  const handleSuccess = () => {
    setStats({ ...stats, bookings: stats.bookings + 1 })

    dispatch(
      setSnackbar({
        message: t('Your booking was created successfully'),
        severity: 'success',
        open: true,
      }),
    )

    navigate(`/companies/${companyId}/bookings`)
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_BOOKING_NOT_AVAILABLE) {
      dispatch(
        setSnackbar({
          message: t('Ops, it seems that the chosen date is no longer available. Please go back and choose another one.'),
          severity: 'error',
          open: true,
        }),
      )
    } else {
      dispatch(
        setSnackbar({
          message: t('An unexpected error occurred while creating booking'),
          severity: 'error',
          open: true,
        }),
      )
    }
  }

  const handleSubmitForm: SubmitHandler<BookingCreateForm> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      bookingApi
        .create(token, companyId, {
          clientId: form.client!.id,
          serviceId: form.service!.id,
          professionalId: form.professional!.id,
          date: form.time!,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <FormProvider {...methods}>
      <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <BookingStepper loading={loading} />
      </Box>
    </FormProvider>
  )
}
