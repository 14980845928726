import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserProfessionalContext from '../../context/userprofessional/UserProfessionalContext'

// ========================|| HOOK - USER PROFESSIONAL BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userProfessional } = useContext(UserProfessionalContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const professionalsUrl = { title: t('Professionals'), url: '/users/me/professionals' }
  const professionalUrl = { title: userProfessional.name }
  const bookings = { title: t('Bookings') }

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      return [professionalsUrl, professionalUrl, bookings]
    }
    default: {
      return []
    }
  }
}

export default useBreadcrumbs
