import { ReactNode } from 'react'

// project imports
import BusinessHoursContext from './BusinessHoursContext'
import BusinessHoursAdjustedContext from './BusinessHoursAdjustedContext'
import useProfessionalId from '../../hooks/professional/useProfessionalId'
import useProfessionalBusinessHours from '../../hooks/businesshour/useProfessionalBusinessHours'
import useProfessionalBusinessHoursAdjusted from '../../hooks/businesshour/useProfessionalBusinessHoursAdjusted'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import Loader from '../../ui-components/extended/progress/Loader/Loader'

// ========================|| PROVIDER - BUSINESS HOURS ||======================== //

export default function ProfessionalBusinessHoursProvider({ children }: { children: ReactNode }): JSX.Element {
  const professionalId = useProfessionalId()

  const {
    loading: loadingBusinessHours,
    error: businessHoursError,
    businessHours,
    setBusinessHours
  } = useProfessionalBusinessHours(professionalId)

  const {
    loading: loadingBusinessHoursAdjusted,
    error: businessHoursAdjustedError,
    businessHoursAdjusted,
    setBusinessHoursAdjusted,
  } = useProfessionalBusinessHoursAdjusted(professionalId)

  const loading = loadingBusinessHours || loadingBusinessHoursAdjusted

  if (!professionalId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (businessHoursError) {
    return <ApiErrorAlert error={businessHoursError} />
  }

  if (businessHoursAdjustedError) {
    return <ApiErrorAlert error={businessHoursAdjustedError} />
  }

  if (!businessHours || !businessHoursAdjusted) {
    return <ApiErrorAlert />
  }

  return (
    <BusinessHoursContext.Provider value={{ businessHours, setBusinessHours }}>
      <BusinessHoursAdjustedContext.Provider value={{ businessHoursAdjusted, setBusinessHoursAdjusted }}>
        {children}
      </BusinessHoursAdjustedContext.Provider>
    </BusinessHoursContext.Provider>
  )
}
