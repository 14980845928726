import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import ProfessionalUserApi from '../../../../api/userprofessional/ProfessionalUserApi'
import ProfessionalUsersContext from '../../../../context/userprofessional/ProfessionalUsersContext'
import useAuth from '../../../../context/auth/useAuth'
import useProfessionalId from '../../../../hooks/professional/useProfessionalId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import ProfessionalUserCreateForm from '../ProfessionalUserCreateForm/ProfessionalUserCreateForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { ProfessionalUser } from '../../../../types/ProfessionalUser'
import { UserProfessionalRole } from '../../../../types/UserProfessionalRole'
import { ApiError } from '../../../../types/ApiError'
import {
  EXCEPTION_USER_NOT_FOUND,
  EXCEPTION_USER_PROFESSIONAL_ALREADY_EXIST,
  EXCEPTION_USER_PROFESSIONAL_LIMIT_REACHED,
} from '../../../../api/exceptions/exceptions'

// apis
const professionalUserApi = new ProfessionalUserApi()

// ========================|| PROFESSIONAL USER - CREATE DIALOG ||======================== //

interface FormValues {
  email: string
  role: UserProfessionalRole
}

interface Props extends DialogProps {
  onClose: () => void
}

export default function ProfessionalUserCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setProfessionalUsers } = useContext(ProfessionalUsersContext)
  const professionalId = useProfessionalId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      role: UserProfessionalRole.VIEWER,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (professionalUser: ProfessionalUser) => {
    setProfessionalUsers(professionalUsers => ({ ...professionalUsers, content: professionalUsers.content.concat(professionalUser) }))

    dispatch(
      setSnackbar({
        message: t('User added successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_USER_NOT_FOUND) {
      methods.setError('email', { message: EXCEPTION_USER_NOT_FOUND })
      return
    }

    if (error.message === EXCEPTION_USER_PROFESSIONAL_ALREADY_EXIST) {
      methods.setError('email', { message: 'The user is already associated with the professional.' })
      return
    }

    if (error.message === EXCEPTION_USER_PROFESSIONAL_LIMIT_REACHED) {
      methods.setError('email', { message: 'The user cannot be linked to any more professionals.' })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while inviting user'),
        severity: 'error',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      professionalUserApi
        .create(token, professionalId, {
          email: form.email,
          role: form.role,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Add Administrator')}</DialogTitle>
          <DialogContent>
            <ProfessionalUserCreateForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid size={12} container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Add')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
