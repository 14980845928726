// material-ui
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'

// project imports
import themePalette from './palette'
import themeTypography from './typography'
import componentStyleOverrides from './overrides/styleOverrides'
import { CustomThemeOptions } from './types'
import colors from './colors/colors'

export const theme = () => {
  const color = colors

  const themeOption: CustomThemeOptions = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.divider,

    // customization
    borderRadius: 8,
    fontFamily: '"Poppins", "Inter", sans-serif',
  }

  const themeOptions: ThemeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    typography: themeTypography(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    shape: {
      borderRadius: themeOption.borderRadius,
    },
    mainContent: {
      backgroundColor: themeOption.colors.grey100,
      width: '100%',
      minHeight: 'calc(100vh - 84px)',
      flexGrow: 1,
      padding: '20px',
      marginTop: '84px',
      marginRight: '20px',
      borderRadius: `${themeOption.borderRadius}px`,
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px',
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem',
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem',
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem',
    },
  }

  const theme: Theme = createTheme(themeOptions)
  theme.components = componentStyleOverrides(themeOption)

  return theme
}

export default theme
