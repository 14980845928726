// project imports
import { axiosInstance } from '../../axios'
import { ServiceProfessional, ServiceProfessionalParams } from '../../types/ServiceProfessional'
import { ServiceProfessionalUpdateDto } from './dto/ServiceProfessionalUpdateDto'

// ========================|| API - SERVICE PROFESSIONAL ||======================== //

export default class ServiceProfessionalApi {
  findAll(token: string, serviceId: string, params: ServiceProfessionalParams): Promise<ServiceProfessional[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/services/${serviceId}/professionals`, config)
  }

  update(token: string, serviceId: string, professionalId: string, serviceProfessional: ServiceProfessionalUpdateDto): Promise<ServiceProfessional> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/services/${serviceId}/professionals/${professionalId}`, serviceProfessional, config)
  }

  delete(token: string, serviceId: string, professionalId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/services/${serviceId}/professionals/${professionalId}`, config)
  }
}
