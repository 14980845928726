import { useContext } from 'react'

// material-ui
import { Box, Button, Step, StepContent, StepLabel, Stepper } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import ClientContext from '../../../../context/client/ClientContext'
import { StepperItem } from '../stepper-items/stepperItems'
import { Service } from '../../../../types/Service'
import { ServiceProfessional } from '../../../../types/ServiceProfessional'
import { isDepositRequired } from '../../../../utils/serviceProfessional'

// ========================|| BOOKING FORM - MOBILE STEPPER ||======================== //

interface Props {
  steps: StepperItem[]
  activeStep: number
  handleNext: () => void
  handleBack: () => void
  loading: boolean
}

export default function MobileStepper({ steps, activeStep, handleNext, handleBack, loading }: Props) {
  // hooks
  const { t } = useTranslation()
  const { client } = useContext(ClientContext)
  const { watch } = useFormContext()

  const confirmationLabel = (): string => {
    const service: Service = watch('service')
    const professional: ServiceProfessional = watch('professional')

    if (isDepositRequired(client, service, professional)) {
      return 'Confirm and pay'
    } else {
      return 'Confirm'
    }
  }

  return (
    <Stepper activeStep={activeStep} orientation='vertical' sx={{ p: '0', overflow: 'hidden' }}>
      {steps.map(item => {
        const stepProps: { completed?: boolean } = {}
        return (
          <Step key={item.id} {...stepProps}>
            <StepLabel>{t(item.title)}</StepLabel>
            <StepContent sx={{ pt: '8px' }} TransitionProps={{ unmountOnExit: false }}>
              {steps[activeStep].component}
              <Box sx={{ mt: 3 }}>
                {activeStep !== steps.length - 1 && (
                  <Button variant='contained' disableElevation onClick={handleNext} sx={{ mr: 1 }}>
                    {t('Next')}
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <LoadingButton type='submit' variant='contained' loading={loading} disableElevation sx={{ mr: 1 }}>
                    {t(confirmationLabel())}
                  </LoadingButton>
                )}
                <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  {t('Back')}
                </Button>
              </Box>
            </StepContent>
          </Step>
        )
      })}
    </Stepper>
  )
}
