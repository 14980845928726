import * as React from 'react'
import { useEffect } from 'react'

// material-ui
import { Autocomplete, AutocompleteProps, Box, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import useServiceProfessionals from '../../../../hooks/serviceprofessional/useServiceProfessionals'
import { resolveCurrency, resolvePrice } from '../../../../utils/serviceProfessional'
import { getNullablePriceString } from '../../../../utils/typography'
import { ServiceDetails } from '../../../../types/Service'
import { ServiceProfessional } from '../../../../types/ServiceProfessional'

// ========================|| UI COMPONENTS - AUTOCOMPLETE - SERVICE PROFESSIONAL ||======================== //

interface Props extends Omit<AutocompleteProps<ServiceProfessional, false, false, false>, 'options' | 'loading' | 'getOptionKey' | 'isOptionEqualToValue'> {
  service: ServiceDetails | null,
  branchId?: string
}

export default function ServiceProfessionalAutocomplete({ service, branchId, ...props }: Props) {
  const { i18n, t } = useTranslation()
  const { loading, serviceProfessionals, setServiceProfessionals } = useServiceProfessionals(service?.id, { branchId })

  useEffect(() => {
    if (!service) {
      setServiceProfessionals([])
    }
  }, [service])

  const getPrice = (serviceProfessional: ServiceProfessional): string => {
    if (service) {
      const price = resolvePrice(service, serviceProfessional)
      const currency = resolveCurrency(service, serviceProfessional)
      return getNullablePriceString(price, currency, i18n.language)
    }

    return ''
  }

  return (
    <Autocomplete
      {...props}
      fullWidth
      options={serviceProfessionals}
      loading={loading}
      getOptionKey={option => option.id}
      isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
      noOptionsText={service ? t('No options') : t('First select a service')}
      getOptionLabel={service => service.name}
      renderOption={(props, serviceProfessional: ServiceProfessional) => (
        <Box component='li' {...props}>
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Box>
              <Typography fontWeight='bold'>{serviceProfessional.name}</Typography>
              <Typography variant='caption'>{serviceProfessional.branch.name}</Typography>
            </Box>
            <Box>
              <Typography variant='caption'>
                {t(getPrice(serviceProfessional))}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    />
  )
}