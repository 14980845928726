import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import LinkOffTwoToneIcon from '@mui/icons-material/LinkOffTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserProfessionalDeleteDialog from '../UserProfessionalDeleteDialog/UserProfessionalDeleteDialog'
import { UserProfessional } from '../../../../types/UserProfessional'

// ========================|| USER PROFESSIONAL - DELETE BUTTON ||======================== //

interface Props {
  userProfessional: UserProfessional
}

export default function UserProfessionalDeleteButton({ userProfessional }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Unlink')}>
        <IconButton color='error' size='large' onClick={handleOpen}>
          <LinkOffTwoToneIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <UserProfessionalDeleteDialog open={open} userProfessional={userProfessional} onClose={handleClose} />
    </>
  )
}
