import { ReactNode } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Drawer } from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BrowserView, MobileView } from 'react-device-detect'

// project imports
import LogoSection from '../LogoSection/LogoSection'
import useDrawer from '../../hooks/useDrawer'
import { drawerWidth } from '../../utils/constants'

// ==============================|| SIDEBAR DRAWER ||============================== //

interface Props {
  children: ReactNode
}

const Sidebar = ({ children }: Props) => {
  // hooks
  const theme = useTheme()
  const { drawerOpened, handleDrawerToggle, matchDownMd, matchUpMd } = useDrawer()

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView renderWithFragment>
        <PerfectScrollbar
          component='div'
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 84px)',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          {children}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView renderWithFragment>
        <Box sx={{ px: 2 }}>
          {children}
        </Box>
      </MobileView>
    </>
  )

  return (
    <Box
      component='nav'
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label='mailbox folders'
    >
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor='left'
        open={!matchDownMd ? drawerOpened : !drawerOpened}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '60px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar
