import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ServiceProfessionalEditDialog from '../ServiceProfessionalEditDialog/ServiceProfessionalEditDialog'
import { ServiceProfessional } from '../../../../types/ServiceProfessional'

// ========================|| PROFESSIONAL SERVICE - EDIT BUTTON ||======================== //

interface Props {
  serviceProfessional: ServiceProfessional
}

export default function ServiceProfessionalEditButton({ serviceProfessional }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t('Customize')}>
        <IconButton color='secondary' size='large' onClick={handleOpen}>
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <ServiceProfessionalEditDialog open={open} serviceProfessional={serviceProfessional} onClose={handleClose} />
    </>
  )
}
