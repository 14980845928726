// project imports
import { axiosInstance } from '../../axios'
import { Booking, BookingParams } from '../../types/Booking'
import { Page } from '../../types/Page'

// ========================|| API - BRANCH BOOKING ||======================== //

export default class BranchBookingApi {
  findAll(token: string, branchId: string, params: BookingParams): Promise<Page<Booking>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        startDate: params.startDate?.toDate(),
        endDate: params.endDate?.toDate(),
        status: params.status,
        professionalIds: params.professionals.map(professional => professional.id),
        search: params.search,
        page: params.page,
        size: params.size,
      }
    }

    return axiosInstance.get(`/api/v1/branches/${branchId}/bookings`, config)
  }
}