// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// ========================|| HOOK - SERVICE TOOLTIP ||======================== //

const useTooltip = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  switch (pathname.split('/').at(-1)) {
    case 'professionals': {
      return t('tooltip.service.professionals')
    }
    default: {
      return t('tooltip.service')
    }
  }
}

export default useTooltip