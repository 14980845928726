// project imports
import { axiosInstance } from '../../axios'
import { Booking, BookingParams } from '../../types/Booking'
import { Page } from '../../types/Page'
import { CompanyBookingCreateDto } from './dto/UserBookingCreateDto'

// ========================|| API - COMPANY BOOKING ||======================== //

export default class CompanyBookingApi {
  findAll(token: string, companyId: string, params: BookingParams): Promise<Page<Booking>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        startDate: params.startDate?.toDate(),
        endDate: params.endDate?.toDate(),
        status: params.status,
        professionalIds: params.professionals.map(professional => professional.id),
        search: params.search,
        page: params.page,
        size: params.size,
        branchId: params.branchId,
        sort: params.sort,
      },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/bookings`, config)
  }

  create(token: string, companyId: string, booking: CompanyBookingCreateDto): Promise<Booking> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/bookings`, booking, config)
  }
}
