import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ProfessionalDeleteDialog from '../ProfessionalDeleteDialog/ProfessionalDeleteDialog'
import { Professional } from '../../../../types/Professional'

// ========================|| PROFESSIONAL - DELETE BUTTON ||======================== //

interface Props {
  professional: Professional
}

export default function ProfessionalDeleteIconButton({ professional }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Delete')}>
        <IconButton aria-label='Delete professional' color='error' size='large' onClick={handleOpen}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <ProfessionalDeleteDialog open={open} professional={professional} onClose={handleClose} />
    </>
  )
}
