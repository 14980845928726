import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Page } from '../../types/Page'
import { ProfessionalUser } from '../../types/ProfessionalUser'
import { UserProfessionalParams } from '../../types/UserProfessional'

// ========================|| CONTEXT - PROFESSIONAL USERS ||======================== //

interface ProfessionalUsersContextType {
  professionalUsers: Page<ProfessionalUser>
  params: UserProfessionalParams
  setProfessionalUsers: Dispatch<SetStateAction<Page<ProfessionalUser>>>
  setParams: Dispatch<SetStateAction<UserProfessionalParams>>
}

const ProfessionalUsersContext = createContext<ProfessionalUsersContextType>({} as ProfessionalUsersContextType)

export default ProfessionalUsersContext