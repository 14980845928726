// project imports
import BookingsContext from '../../../context/booking/BookingsContext'
import useProfessionalId from '../../../hooks/professional/useProfessionalId'
import useProfessionalBookings from '../../../hooks/booking/useProfessionalBookings'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import BookingCard from './BookingCard/BookingCard'
import { Layout } from '../../../types/Layout'

// ========================|| BOOKINGS ||======================== //

export default function Bookings() {
  const professionalId = useProfessionalId()
  const state = useProfessionalBookings(professionalId)

  if (state.firstLoading) {
    return <Loader />
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <BookingsContext.Provider value={{ ...state, layout: Layout.PROFESSIONAL }}>
      <BookingCard />
    </BookingsContext.Provider>
  )
}
