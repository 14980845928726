import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserProfessionalApi from '../../api/userprofessional/UserProfessionalApi'
import { UserProfessional, UserProfessionalParams } from '../../types/UserProfessional'
import { Page, DEFAULT_PAGE, DEFAULT_PARAMS } from '../../types/Page'
import { ApiError } from '../../types/ApiError'

// apis
const userProfessionalApi = new UserProfessionalApi()

  // ========================|| HOOK - USER PROFESSIONALS ||======================== //

export default function useUserProfessionals() {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [userProfessionals, setUserProfessionals] = useState<Page<UserProfessional>>(DEFAULT_PAGE)
  const [params, setParams] = useState<UserProfessionalParams>(DEFAULT_PARAMS)

  const searchUserProfessionals = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      userProfessionalApi
        .findAll(token, params)
        .then(data => setUserProfessionals(data))
        .catch(error => setError(error))
        .finally(() => {
          setLoading(false)
          setFirstLoading(false)
        })
    })
  }

  useEffect(searchUserProfessionals, [params])

  return { firstLoading, loading, error, userProfessionals, params, setUserProfessionals, setParams }
}