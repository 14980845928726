import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { ServiceProfessional, ServiceProfessionalParams } from '../../types/ServiceProfessional'

// ========================|| CONTEXT - SERVICE PROFESSIONAL ||======================== //

interface ServiceProfessionalContextType {
  serviceProfessionals: ServiceProfessional[]
  params: ServiceProfessionalParams
  setServiceProfessionals: Dispatch<SetStateAction<ServiceProfessional[]>>
  setParams: Dispatch<SetStateAction<ServiceProfessionalParams>>
}

const ServiceProfessionalContext = createContext<ServiceProfessionalContextType>({} as ServiceProfessionalContextType)

export default ServiceProfessionalContext