import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BranchUserDeleteDialog from '../BranchUserDeleteDialog/BranchUserDeleteDialog'
import { BranchUser } from '../../../../types/BranchUser'

// ========================|| BRANCH USER - DELETE BUTTON ||======================== //

interface Props {
  branchUser: BranchUser
}

export default function BranchUserDeleteButton({ branchUser }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Delete')}>
        <IconButton aria-label='Delete user' color='error' size='large' onClick={handleOpen}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <BranchUserDeleteDialog open={open} branchUser={branchUser} onClose={handleClose} />
    </>
  )
}
