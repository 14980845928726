import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ProfessionalBusinessHoursAdjustedApi from '../../api/businesshouradjusted/ProfessionalBusinessHoursAdjustedApi'
import { BusinessHourAdjusted } from '../../types/BusinessHour'
import { ApiError } from '../../types/ApiError'

// apis
const businessHoursApi = new ProfessionalBusinessHoursAdjustedApi()

// ========================|| HOOK - BUSINESS HOURS - PROFESSIONAL ||======================== //

export default function useProfessionalBusinessHoursAdjusted(professionalId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [businessHoursAdjusted, setBusinessHoursAdjusted] = useState<BusinessHourAdjusted[]>([])

  useEffect(() => {
    auth!.getIdToken().then(token => {
      businessHoursApi
        .findByProfessionalId(token, professionalId)
        .then(data => setBusinessHoursAdjusted(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }, [professionalId])

  return { loading, error, businessHoursAdjusted, setBusinessHoursAdjusted }
}
