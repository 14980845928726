// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import ProfessionalBusinessHoursCard from '../ProfessionalBusinessHoursCard/ProfessionalBusinessHoursCard'
import ProfessionalBusinessHoursAdjustedCard from '../ProfessionalBusinessHoursAdjustedCard/ProfessionalBusinessHoursAdjustedCard'
import ProfessionalBusinessHoursProvider from '../../../../context/businesshour/ProfessionalBusinessHoursProvider'

// ========================|| PROFESSIONAL TABS - BUSINESS HOURS ||======================== //

export default function ProfessionalBusinessHoursTab() {
  return (
    <ProfessionalBusinessHoursProvider>
      <Grid container spacing={3}>
        <Grid size={12}>
          <ProfessionalBusinessHoursCard />
        </Grid>
        <Grid size={12}>
          <ProfessionalBusinessHoursAdjustedCard />
        </Grid>
      </Grid>
    </ProfessionalBusinessHoursProvider>
  )
}
