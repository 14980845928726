import { useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Chip, Grid2 as Grid, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingFilterTag from '../BookingFilterTag/BookingFilterTag'
import { Professional } from '../../../../types/Professional'

// ========================|| BOOKING - FILTER TAGS - PROFESSIONAL ||======================== //

export default function BookingFilterProfessionalTag() {
  const { t } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const handleDelete = (deleted: Professional) => {
    setParams(filter => ({
      ...filter,
      professionals: filter.professionals.filter(professional => professional.id !== deleted.id),
      page: 0,
    }))
  }

  return (
    <BookingFilterTag title={t('Professionals')}>
      <Grid container spacing={1}>
        {params.professionals.map(professional => (
          <Grid key={professional.id}>
            <Chip
              label={professional.name}
              color='secondary'
              size={matchesXS ? 'small' : 'medium'}
              onDelete={() => handleDelete(professional)}
            />
          </Grid>
        ))}
      </Grid>
    </BookingFilterTag>
  )
}