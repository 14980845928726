import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserProfessionalContext from '../../context/userprofessional/UserProfessionalContext'
import { UserProfessionalRole } from '../../types/UserProfessionalRole'

// ========================|| HOOK - USER PROFESSIONAL TOOLTIP ||======================== //

const useTooltip = () => {
  const { userProfessional } = useContext(UserProfessionalContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      switch (userProfessional.role) {
        case UserProfessionalRole.EDITOR:
          return t('tooltip.professional.bookings.editor')
        case UserProfessionalRole.VIEWER:
          return t('tooltip.professional.bookings.viewer')
      }
    }
  }
}

export default useTooltip
