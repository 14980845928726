import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import ProfessionalUsersContext from '../../../../context/userprofessional/ProfessionalUsersContext'
import ProfessionalUserApi from '../../../../api/userprofessional/ProfessionalUserApi'
import useAuth from '../../../../context/auth/useAuth'
import useProfessionalId from '../../../../hooks/professional/useProfessionalId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import ProfessionalUserEditForm from '../ProfessionalUserEditForm/ProfessionalUserEditForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { ProfessionalUser } from '../../../../types/ProfessionalUser'
import { UserProfessionalRole } from '../../../../types/UserProfessionalRole'

// apis
const professionalUserApi = new ProfessionalUserApi()

// ========================|| PROFESSIONAL USER - EDIT DIALOG ||======================== //

interface FormValues {
  role: UserProfessionalRole
}

interface Props extends DialogProps {
  professionalUser: ProfessionalUser
  onClose: () => void
}

export default function ProfessionalUserEditDialog({ professionalUser, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setProfessionalUsers } = useContext(ProfessionalUsersContext)
  const professionalId = useProfessionalId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    defaultValues: {
      role: professionalUser.role,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (updated: ProfessionalUser) => {
    setProfessionalUsers(professionalUsers => ({
      ...professionalUsers,
      content: professionalUsers.content.map(professionalUser =>
        professionalUser.id === updated.id && professionalUser.id === updated.id ? updated : professionalUser,
      ),
    }))

    methods.reset({
      role: updated.role,
    })

    dispatch(
      setSnackbar({
        message: t('User updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    methods.reset()
    setLoading(false)
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      professionalUserApi.update(token, professionalId, professionalUser.id, form).then(handleSuccess).catch(handleError).finally(handleCloseForm)
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Edit User')}</DialogTitle>
          <DialogContent>
            <ProfessionalUserEditForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  size='large'
                  loading={loading}
                  disabled={!methods.formState.isDirty}
                  fullWidth
                  disableElevation
                >
                  {t('Update')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
