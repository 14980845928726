import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ServiceProfessionalApi from '../../api/serviceprofessional/ServiceProfessionalApi'
import { ServiceProfessional, ServiceProfessionalParams } from '../../types/ServiceProfessional'
import { ApiError } from '../../types/ApiError'

// apis
const serviceProfessionalApi = new ServiceProfessionalApi()

// ========================|| HOOK - SERVICE PROFESSIONALS ||======================== //

export default function useServiceProfessionals(serviceId?: string, serviceProfessionalParams: ServiceProfessionalParams = {}) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [serviceProfessionals, setServiceProfessionals] = useState<ServiceProfessional[]>([])
  const [params, setParams] = useState(serviceProfessionalParams)

  useEffect(() => {
    if (serviceId) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        serviceProfessionalApi
          .findAll(token, serviceId, params)
          .then(data => setServiceProfessionals(data))
          .catch(error => setError(error))
          .finally(() => setLoading(false))
      })
    } else {
      setLoading(false)
    }
  }, [serviceId, params])

  return { loading, error, serviceProfessionals, params, setServiceProfessionals, setParams }
}
