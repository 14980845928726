// material-ui
import { Grid2 as Grid, Typography } from '@mui/material'

// project imports
import ProfessionalDeleteButton from '../ProfessionalDeleteButton/ProfessionalDeleteButton'
import ProfessionalEditButton from '../ProfessionalEditButton/ProfessionalEditButton'
import { Professional } from '../../../../types/Professional'

// ========================|| PROFESSIONAL - LIST ITEM ||======================== //

interface Props {
  professional: Professional
}

export default function ProfessionalListItem({ professional }: Props) {
  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography variant='subtitle1'>{professional.name}</Typography>
        <Typography variant='caption'>{professional.branch.name}</Typography>
      </Grid>
      <Grid size={12} container spacing={1}>
        <Grid size={6}>
          <ProfessionalEditButton professional={professional} />
        </Grid>
        <Grid size={6}>
          <ProfessionalDeleteButton professional={professional} />
        </Grid>
      </Grid>
    </Grid>
  )
}
