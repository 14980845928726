// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { UserProfessionalRole } from '../../../../types/UserProfessionalRole'

// ========================|| PROFESSIONAL USER - ROLE CHIP ||======================== //

interface Props {
  role: UserProfessionalRole
}

export default function ProfessionalUserRoleChip({ role }: Props) {
  const { t } = useTranslation()

  switch (role) {
    case UserProfessionalRole.EDITOR:
      return <Chip label={t('Editor')} size='small' color='primary' />
    case UserProfessionalRole.VIEWER:
      return <Chip label={t('Viewer')} size='small' color='secondary' />
    default:
      return <Chip label={t('Unknown')} size='small' color='error' />
  }
}
