import { Fragment, useContext } from 'react'

// material-ui
import { Box, Button, Step, StepLabel, Stepper } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

// project imports
import ClientContext from '../../../../context/client/ClientContext'
import { StepperItem } from '../stepper-items/stepperItems'
import { Service } from '../../../../types/Service'
import { ServiceProfessional } from '../../../../types/ServiceProfessional'
import { isDepositRequired } from '../../../../utils/serviceProfessional'

// ========================|| BOOKING FORM - BROWSER STEPPER ||======================== //

interface Props {
  steps: StepperItem[]
  activeStep: number
  handleNext: () => void
  handleBack: () => void
  loading: boolean
}

export default function BrowserStepper({ steps, activeStep, handleNext, handleBack, loading }: Props) {
  // hooks
  const { t } = useTranslation()
  const { client } = useContext(ClientContext)
  const { formState, watch } = useFormContext()

  const isStepFailed = (fields: string[]) => {
    return fields.some(field => Object.keys(formState.errors).includes(field))
  }

  const confirmationLabel = (): string => {
    const service: Service = watch('service')
    const professional: ServiceProfessional = watch('professional')

    if (isDepositRequired(client, service, professional)) {
      return 'Confirm and pay'
    } else {
      return 'Confirm'
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {steps.map(item => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: { error?: boolean } = {}

          if (isStepFailed(item.fields)) {
            labelProps.error = true
          }

          return (
            <Step key={item.id} {...stepProps}>
              <StepLabel {...labelProps}>{t(item.title)}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Fragment>
        <Box>{steps[activeStep].component}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 3 }}>
          <Button color='primary' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} disableElevation>
            {t('Back')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep !== steps.length - 1 && (
            <Button variant='contained' onClick={handleNext} disableElevation>
              {t('Next')}
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <LoadingButton type='submit' variant='contained' loading={loading} disableElevation>
              {t(confirmationLabel())}
            </LoadingButton>
          )}
        </Box>
      </Fragment>
    </Box>
  )
}
