// material-ui
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
  useTheme,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

// third-party
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { getPriceString } from '../../../../utils/typography'

interface Props {
  title: string
  description: string
  price: number
  aditional: number
  features: string[]
}

export default function PriceCard({ title, description, price, aditional, features }: Props) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [professionals, setProfessionals] = useState(1)

  const total = useMemo(() => price + (professionals - 1) * aditional, [professionals])

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setProfessionals(newValue)
    }
  }

  return (
    <Card
      variant='outlined'
      sx={{
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant='h2' textAlign='center'>
              {t(title)}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography component='p' variant='body2' textAlign='center' color='text.primary'>
              {t(description)}
            </Typography>
          </Grid>
          <Grid size={12}>
            <List disablePadding>
              {features.map(feature => (
                <ListItem key={feature} disableGutters>
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: theme.palette.warning.main,
                      }}
                    >
                      <CheckIcon sx={{ fontSize: '0.75rem' }} />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color='text.primary'>{t(feature)}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Box mb={1}>
              <Typography variant='subtitle1' gutterBottom>
                Seleccioná la cantidad de profesionales
              </Typography>
              <Typography variant='body1'>Los profesionales son aquellas personas de tu emprendimiento que requieran una agenda de turnos.</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Typography mr={3}>1</Typography>
              <Slider value={professionals} min={1} step={1} max={50} onChange={handleChange} valueLabelDisplay='auto' />
              <Typography ml={3}>50</Typography>
            </Box>
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Typography variant='h1' textAlign='center'>
              {getPriceString(total, 'ARS', 'es-AR', 0)}
            </Typography>
            <Typography variant='h5' color='text.primary' textAlign='center'>
              {t('Finales por mes')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          p: theme.spacing(4),
          pt: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button href={'/signin'} variant='outlined' disableElevation>
          {t('Probar 30 días gratis')}
        </Button>
      </CardActions>
    </Card>
  )
}
