import { useContext, useState } from 'react'

// material-ui
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../../../context/usercompany/UserCompanyContext'
import CompanyUserDeleteDialog from '../CompanyUserDeleteDialog/CompanyUserDeleteDialog'
import { CompanyUser } from '../../../../types/CompanyUser'
import { UserCompanyRole } from '../../../../types/UserCompanyRole'

// ========================|| COMPANY USER - DELETE BUTTON ||======================== //

interface Props extends IconButtonProps {
  companyUser: CompanyUser
}

export default function CompanyUserDeleteIconButton({ companyUser, ...props }: Props) {
  // hooks
  const { t } = useTranslation()
  const { userCompany } = useContext(UserCompanyContext)

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const disabled = companyUser.role === UserCompanyRole.OWNER || userCompany.role === UserCompanyRole.ADMIN

  const title = () => {
    if (userCompany.role === UserCompanyRole.ADMIN) {
      return 'You don\'t have permission to do this'
    } else if (companyUser.role === UserCompanyRole.OWNER) {
      return 'Company owner cannot be deleted'
    } else {
      return 'Delete'
    }
  }

  return (
    <>
      <Tooltip title={t(title())}>
        <span>
          <IconButton {...props} color='error' size='large' disabled={disabled} onClick={handleOpen}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
      <CompanyUserDeleteDialog open={open} companyUser={companyUser} onClose={handleClose} />
    </>
  )
}
