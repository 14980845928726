import React, { useContext } from 'react'

// material-ui
import { Grid2 as Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserProfessionalsContext from '../../../../context/userprofessional/UserProfessionalsContext'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import ProfessionalUserRoleChip from '../../../CompanyLayout/Professional/ProfessionalUserRoleChip/ProfessionalUserRoleChip'
import UserProfessionalSeeButton from '../UserProfessionalSeeButton/UserProfessionalSeeButton'
import UserProfessionalDeleteButton from '../UserProfessionalDeleteButton/UserProfessionalDeleteButton'
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'

// ========================|| USER PROFESSIONAL - TABLE ||======================== //

export default function UserProfessionalTable() {
  // hooks
  const { t } = useTranslation()
  const { userProfessionals, params, setParams } = useContext(UserProfessionalsContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Company')}</TableCell>
              <TableCell>{t('Professional')}</TableCell>
              <TableCell align='center'>{t('Role')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userProfessionals.content.map(userProfessional => (
              <TableRow key={userProfessional.id}>
                <TableCell>
                  <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
                    <Grid>
                      <CompanyAvatar active={userProfessional.company.active} />
                    </Grid>
                    <Grid>
                      <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
                        {userProfessional.company.displayName}
                      </Typography>
                      <Typography variant='caption' align='left' whiteSpace='nowrap'>
                        @{userProfessional.company.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>{userProfessional.name}</TableCell>
                <TableCell align='center'>
                  <ProfessionalUserRoleChip role={userProfessional.role} />
                </TableCell>
                <TableCell align='center'>
                  <UserProfessionalSeeButton userProfessional={userProfessional} />
                  <UserProfessionalDeleteButton userProfessional={userProfessional} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={userProfessionals.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
