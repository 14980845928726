import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingConfirmDialog from '../BookingConfirmDialog/BookingConfirmDialog'
import { Booking, BookingStatus, DepositStatus } from '../../../../types/Booking'

// ========================|| BOOKING - CONFIRM BUTTON ||======================== //

interface Props {
  booking: Booking
}

export default function BookingConfirmIconButton({ booking }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isNotPending = booking.status !== BookingStatus.PENDING

  const isExpired = new Date(booking.date).getTime() < new Date().getTime()

  const isDepositPending = booking.depositStatus === DepositStatus.PENDING ||
    booking.depositStatus === DepositStatus.AUTHORIZED ||
    booking.depositStatus === DepositStatus.IN_PROCESS

  const isDisabled = isNotPending || isExpired || isDepositPending

  const title = () => {
    if (!isExpired && isDepositPending) {
      return 'The booking will be confirmed once the client pays the deposit'
    }

    return 'Confirm'
  }

  return (
    <>
      <Tooltip title={t(title())}>
        <span>
          <IconButton color='success' size='large' onClick={handleOpen} disabled={isDisabled}>
            <CheckCircleTwoToneIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
      <BookingConfirmDialog open={open} booking={booking} onClose={handleClose} />
    </>
  )
}
