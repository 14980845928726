import React, { useContext } from 'react'

// material-ui
import { TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

// project imports
import ServiceProfessionalContext from '../../../../context/serviceprofessional/ServiceProfessionalContext'
import ServiceContext from '../../../../context/service/ServiceContext'
import usePagination from '../../../../hooks/pagination/usePagination'
import SubTable from '../../../../ui-components/extended/table/SubTable'
import ServiceProfessionalEditButton from '../ServiceProfessionalEditButton/ServiceProfessionalEditButton'
import { ServiceProfessional } from '../../../../types/ServiceProfessional'
import { locale } from '../../../../utils/locale'
import { getNullablePriceString } from '../../../../utils/typography'

// ========================|| PROFESSIONAL - TABLE ||======================== //

export default function ServiceProfessionalTable() {
  // hooks
  const { i18n, t } = useTranslation()
  const { service } = useContext(ServiceContext)
  const { serviceProfessionals } = useContext(ServiceProfessionalContext)
  const { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(serviceProfessionals)

  const getEnabledDays = (serviceProfessional: ServiceProfessional): string => {
    const enabledDays = serviceProfessional.availableWeekdays
      .filter(weekday => !serviceProfessional.disabledWeekdays.includes(weekday))
      .map(weekday => capitalize(dayjs().day(weekday).locale(locale(i18n.language)).format('dd')))

    if (enabledDays.length > 0) {
      return enabledDays.join(', ')
    } else {
      return t('There are no days enabled')
    }
  }

  const hasCustomPrice = (serviceProfessional: ServiceProfessional): boolean => {
    return serviceProfessional.price !== null
  }

  const hasCustomEnabledDays = (serviceProfessional: ServiceProfessional): boolean => {
    if (serviceProfessional.disabledWeekdays.length === 0) return false

    return serviceProfessional.disabledWeekdays.some(weekday => serviceProfessional.availableWeekdays.includes(weekday))
  }

  const getPrice = (serviceProfessional: ServiceProfessional) => {
    const price = serviceProfessional.price !== null ? serviceProfessional.price : service.price
    const currency = serviceProfessional.currency || service.currency
    return getNullablePriceString(price, currency, i18n.language)
  }

  return (
    <>
      <TableContainer>
        <SubTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Name')}</TableCell>
              <TableCell>{t('Branch')}</TableCell>
              <TableCell align='right'>{t('Price')}</TableCell>
              <TableCell>{t('Enabled Days')}</TableCell>
              <TableCell align='center' width={150}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((serviceProfessional: ServiceProfessional) => (
              <TableRow key={serviceProfessional.id}>
                <TableCell>{serviceProfessional.name}</TableCell>
                <TableCell>{serviceProfessional.branch.name}</TableCell>
                <TableCell align='right'>
                  <Typography display='flex' justifyContent='end' alignItems='center'>
                    {t(getPrice(serviceProfessional))}
                    {hasCustomPrice(serviceProfessional) && (
                      <Tooltip title={t('Custom price')}>
                        <Typography component='span' color='warning.dark' ml='4px' sx={{ cursor: 'default' }}>
                          ●
                        </Typography>
                      </Tooltip>
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography display='flex' alignItems='center'>
                    {getEnabledDays(serviceProfessional)}
                    {hasCustomEnabledDays(serviceProfessional) && (
                      <Tooltip title={t('Custom enabled days')}>
                        <Typography component='span' color='warning.dark' ml='4px' sx={{ cursor: 'default' }}>
                          ●
                        </Typography>
                      </Tooltip>
                    )}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <ServiceProfessionalEditButton serviceProfessional={serviceProfessional} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SubTable>
      </TableContainer>
      <TablePagination
        component='div'
        count={serviceProfessionals.length}
        rowsPerPageOptions={[5, 10, 20]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ '.MuiTablePagination-toolbar': { padding: '0 20px' } }}
        slotProps={{ select: { inputProps: { 'aria-label': 'rows per page' }, native: true } }}
        labelRowsPerPage={t('Rows per page:')}
      />
    </>
  )
}
