// material-ui
import { CardContent, Divider, Grid2 as Grid } from '@mui/material'

// project imports
import { useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import UserProfessionalSearchBar from '../UserProfessionalSearchBar/UserProfessionalSearchBar'
import UserProfessionalTable from '../UserProfessionalTable/UserProfessionalTable'

// ========================|| USER PROFESSIONAL - CARD ||======================== //

export default function UserProfessionalCard() {
  // hooks
  const { t } = useTranslation()

  return (
    <MainCard title={t('Professionals')} content={false} border={false}>
      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid size='grow'>
            <UserProfessionalSearchBar />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <UserProfessionalTable />
    </MainCard>
  )
}