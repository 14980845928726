// material-ui
import { Autocomplete, AutocompleteProps } from '@mui/material'

// third-party
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

// project imports
import useTimes from '../../../../hooks/availability/useTimes'
import { ServiceDetails } from '../../../../types/Service'
import { ServiceProfessional } from '../../../../types/ServiceProfessional'

// ========================|| UI COMPONENTS - AUTOCOMPLETE - TIME ||======================== //

interface Props extends Omit<AutocompleteProps<string, false, false, false>, 'options' | 'loading' | 'getOptionKey' | 'isOptionEqualToValue'> {
  service: ServiceDetails | null
  professional: ServiceProfessional | null
  date: Dayjs | null
}

export default function TimeAutocomplete({ service, professional, date, ...props }: Props) {
  const { t } = useTranslation()
  const { loading, timeSlots } = useTimes(service?.id, professional?.id, date)

  const shouldOptionDisabled = (dateTime: string): boolean => {
    const timeSlot = timeSlots.find(timeSlot => timeSlot.startTime === dateTime)
    return timeSlot ? !timeSlot.available : true
  }

  return (
    <Autocomplete
      {...props}
      options={timeSlots.map(timeSlot => timeSlot.startTime)}
      loading={loading}
      loadingText={t('Loading...')}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionDisabled={shouldOptionDisabled}
      getOptionLabel={option => dayjs(option).tz(professional?.branch.address.timezone).format('HH:mm')}
    />
  )
}
