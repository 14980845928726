// material-ui
import { Divider, Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material'

// project imports
import useBreadcrumbs from '../../../../hooks/company/useBreadcrumbs'
import useTooltip from '../../../../hooks/company/useTooltip'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import MainContent from '../../../../ui-components/extended/table/MainContent'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import ProfessionalSearchBar from '../ProfessionalSearchBar/ProfessionalSearchBar'
import ProfessionalCreateButton from '../ProfessionalCreateButton/ProfessionalCreateButton'
import ProfessionalTable from '../ProfessionalTable/ProfessionalTable'
import ProfessionalList from '../ProfessionalList/ProfessionalList'

// ========================|| PROFESSIONAL - CARD ||======================== //

export default function ProfessionalCard() {
  const theme = useTheme()
  const items = useBreadcrumbs()
  const tooltip = useTooltip()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content={false} border={false}>
      <MainContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid size='grow'>
            <ProfessionalSearchBar />
          </Grid>
          <Grid textAlign='center'>
            <ProfessionalCreateButton />
          </Grid>
        </Grid>
      </MainContent>
      <Divider />
      {matchesXS ? <ProfessionalList /> : <ProfessionalTable />}
    </MainCard>
  )
}
