import React, { useState } from 'react'

// material-ui
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
// third-party
import { useTranslation } from 'react-i18next'

import { Controller, useFormContext } from 'react-hook-form'
// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useBranches from '../../../../hooks/branch/useBranches'
import useServices from '../../../../hooks/service/useServices'
import { ProfessionalCreateForm } from '../../../../types/Professional'

// ========================|| PROFESSIONAL - FORM ||======================== //

export default function ProfessionalForm() {
  // hooks
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { control, resetField } = useFormContext<ProfessionalCreateForm>()
  const { loading: loadingBranches, branches } = useBranches(companyId)
  const { loading: loadingServices, services } = useServices(companyId)

  const [linkUser, setLinkUser] = useState(false)

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='branch'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Autocomplete
                id='branch'
                options={branches}
                value={value || null}
                loading={loadingBranches}
                disableClearable
                onChange={(e, branch) => onChange(branch)}
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                getOptionLabel={branch => branch.name}
                renderInput={params => (
                  <TextField {...params} label={t('Branch')} error={!!error} helperText={error ? t(error.message as string) : null} />
                )}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='services'
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
              <Autocomplete
                options={services}
                value={value || null}
                loading={loadingServices}
                multiple
                limitTags={5}
                disableCloseOnSelect
                onChange={(e, service) => onChange(service)}
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                getOptionLabel={service => service.name}
                renderOption={(props, service, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      id={service.name}
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {service.name}
                  </li>
                )}
                renderInput={params => <TextField {...params} label={t('Services')} />}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid container size={12} spacing={2}>
        <Grid size={12}>
          <FormControl component='fieldset' variant='standard' fullWidth>
            <FormLabel component='legend'>
              <Typography variant='subtitle1'>{t('Platform access')}</Typography>
            </FormLabel>
            <FormControlLabel
              value={linkUser}
              onChange={(event, checked) => {
                resetField('email')
                setLinkUser(checked)
              }}
              control={<Switch id='createUserSwitch' />}
              label={t('Link a user to this professional')}
            />
            {!linkUser && (
              <FormHelperText>
                {t('If you don’t know the email, you can link the user later in the "Administrators" section of the professional’s profile.')}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {linkUser && (
          <Grid size={12}>
            <Controller
              name='email'
              control={control}
              rules={{
                required: 'This field is required.',
                pattern: {
                  value: /^[^@]+@[^@]+$/,
                  message: 'Must be a valid email.',
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id='mail'
                  label='Email'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message as string) : t('professional_linkUser')}
                  slotProps={{
                    input: {
                      autoComplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
