import { useContext, useMemo } from 'react'

// third-party
import debouce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import SearchBar from '../../../../ui-components/extended/textfield/SearchBar'

// ========================|| BOOKING - SEARCH BAR ||======================== //

export default function BookingSearchBar() {
  // hooks
  const { t } = useTranslation()
  const { setParams } = useContext(BookingsContext)

  const handleSearch = (event: any) => {
    const search = event.target.value || undefined
    setParams(filter => ({ ...filter, search, page: 0 }))
  }

  const debounceSearch = useMemo(() => {
    return debouce(handleSearch, 300)
  }, [])

  return <SearchBar placeholder={t('Search') as string} onChange={debounceSearch} />
}
