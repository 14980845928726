import { useContext, useState } from 'react'

// third-party
import { useTranslation } from 'react-i18next'

// material-ui
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// project imports
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import ProfessionalCreateDialog from '../ProfessionalCreateDialog/ProfessionalCreateDialog'
import { isInactive } from '../../../../utils/subscription'

// ========================|| PROFESSIONAL - CREATE BUTTON ||======================== //

export default function ProfessionalCreateButton() {
  // hooks
  const { t } = useTranslation()
  const { stats } = useContext(CompanyStatsContext)
  const { subscription } = useContext(OptionalSubscriptionContext)

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tooltipTitle = () => {
    if (isInactive(subscription)) {
      return 'An active subscription is required to add professionals.'
    }

    if (subscription && subscription.professionals === stats.professionals) {
      return 'Professional limit reached. Please upgrade your subscription to create more professionals.'
    }

    return 'Add Professional'
  }

  const isDisabled = isInactive(subscription) || (subscription && subscription.professionals === stats.professionals)

  return (
    <>
      <Tooltip title={t(tooltipTitle())}>
        <span>
          <Fab
            color='primary'
            aria-label='Add professional'
            sx={{
              width: '32px',
              height: '32px',
              minHeight: '32px',
              boxShadow: 'none',
            }}
            disabled={isDisabled}
            onClick={handleOpen}
          >
            <AddIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <ProfessionalCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
