// material-ui
import { IconButton, Tooltip } from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import { UserProfessional } from '../../../../types/UserProfessional'

// ========================|| USER PROFESSIONAL - SEE BUTTON ||======================== //

interface Props {
  userProfessional: UserProfessional
}

export default function UserProfessionalSeeButton({ userProfessional }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/professionals/${userProfessional.id}/bookings`)
  }

  return (
    <Tooltip title={t('View')}>
      <IconButton aria-label='View professional' color='primary' size='large' onClick={handleNavigate}>
        <VisibilityTwoToneIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
