import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import LinkOffTwoToneIcon from '@mui/icons-material/LinkOffTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyUnlinkDialog from '../UserCompanyUnlinkDialog/UserCompanyUnlinkDialog'
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - UNLINK BUTTON ||======================== //

interface Props {
  userCompany: UserCompany
}

export default function UserCompanyUnlinkIconButton({ userCompany }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Unlink')}>
        <IconButton color='error' size='large' onClick={handleOpen}>
          <LinkOffTwoToneIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <UserCompanyUnlinkDialog open={open} userCompany={userCompany} onClose={handleClose} />
    </>
  )
}
