import { createContext, Dispatch, SetStateAction } from 'react'
import { Page } from '../../types/Page'
import { ApiError } from '../../types/ApiError'
import { UserProfessional, UserProfessionalParams } from '../../types/UserProfessional'

// ========================|| CONTEXT - USER PROFESSIONALS ||======================== //

interface UserProfessionalsContextType {
  loading: boolean
  userProfessionals: Page<UserProfessional>
  error?: ApiError
  params: UserProfessionalParams
  setUserProfessionals: Dispatch<SetStateAction<Page<UserProfessional>>>
  setParams: Dispatch<SetStateAction<UserProfessionalParams>>
}

const UserProfessionalsContext = createContext<UserProfessionalsContextType>({} as UserProfessionalsContextType)

export default UserProfessionalsContext
