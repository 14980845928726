// third-party
import { IconCalendarTime } from '@tabler/icons-react'

// project imports
import useBranchId from '../../../hooks/branch/useBranchId'
import MenuGroup from '../../../layout/Menu/MenuGroup'
import MenuItem from '../../../layout/Menu/MenuItem'

// ========================|| LAYOUT - MENU ||======================== //

export default function Menu() {
  const branchId = useBranchId()

  return (
    <MenuGroup title='Workspace'>
      <MenuItem title='Bookings' url={`/branches/${branchId}/bookings`} icon={IconCalendarTime} />
    </MenuGroup>
  )
}
