import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ProfessionalUserEditDialog from '../ProfessionalUserEditDialog/ProfessionalUserEditDialog'
import { ProfessionalUser } from '../../../../types/ProfessionalUser'

// ========================|| PROFESSIONAL USER - EDIT BUTTON ||======================== //

interface Props {
  professionalUser: ProfessionalUser
}

export default function ProfessionalUserEditButton({ professionalUser }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t('Edit User')}>
        <IconButton color='secondary' size='large' onClick={handleOpen}>
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <ProfessionalUserEditDialog open={open} professionalUser={professionalUser} onClose={handleClose} />
    </>
  )
}
