import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { UserProfessional } from '../../types/UserProfessional'

// ========================|| CONTEXT - USER COMPANY ||======================== //

interface UserProfessionalContextType {
  userProfessional: UserProfessional
  setUserProfessional: Dispatch<SetStateAction<UserProfessional | undefined>>
}

const UserProfessionalContext = createContext<UserProfessionalContextType>({} as UserProfessionalContextType)

export default UserProfessionalContext
