import React, { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import UserProfessionalApi from '../../../../api/userprofessional/UserProfessionalApi'
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserProfessionalsContext from '../../../../context/userprofessional/UserProfessionalsContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { UserProfessional } from '../../../../types/UserProfessional'

// apis
const userProfessionalApi = new UserProfessionalApi()

// ========================|| USER PROFESSIONAL - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  userProfessional: UserProfessional
  onClose: () => void
}

export default function UserProfessionalDeleteDialog({ userProfessional, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(UserStatsContext)
  const { setUserProfessionals } = useContext(UserProfessionalsContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeProfessional = (userProfessionalId: string) => {
    setUserProfessionals(userProfessionals => ({
      ...userProfessionals,
      content: userProfessionals.content.filter(userProfessional => userProfessional.id !== userProfessionalId),
    }))
  }

  const reduceProfessionalStat = () => {
    setStats({ ...stats, professionals: stats.professionals - 1 })
  }

  const handleSuccess = () => {
    removeProfessional(userProfessional.id)
    reduceProfessionalStat()

    dispatch(
      setSnackbar({
        message: t('User unlinked successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while unlinking user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      userProfessionalApi.delete(token, userProfessional.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-userprofessional-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-userprofessional-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to be unlinked from the professional') + ' '}
          <strong>{userProfessional.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Unlink')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
