// material-ui
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { Professional } from '../../../../types/Professional'

// ========================|| PROFESSIONAL - EDIT BUTTON ||======================== //

interface Props {
  professional: Professional
}

export default function ProfessionalEditIconButton({ professional }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${professional.id}`)
  }

  return (
    <Tooltip title={t('Edit')}>
      <IconButton aria-label='Edit professional' color='secondary' size='large' onClick={handleClick}>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
