// project imports
import { axiosInstance } from '../../axios'
import { Professional } from '../../types/Professional'
import { ProfessionalUpdateDto } from './dto/ProfessionalUpdateDto'

// ========================|| API - PROFESSIONAL ||======================== //

export default class ProfessionalApi {
  findById(token: string, professionalId: string): Promise<Professional> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/professionals/${professionalId}`, config)
  }

  update(token: string, professionalId: string, professional: ProfessionalUpdateDto): Promise<Professional> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/professionals/${professionalId}`, professional, config)
  }

  delete(token: string, professionalId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/professionals/${professionalId}`, config)
  }
}
