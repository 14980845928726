import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { ApiError } from '../../types/ApiError'
import { Professional, ProfessionalParams } from '../../types/Professional'

// ========================|| CONTEXT - PROFESSIONAL ||======================== //

interface ProfessionalContextType {
  loading: boolean
  error?: ApiError
  professionals: Professional[]
  params: ProfessionalParams
  setProfessionals: Dispatch<SetStateAction<Professional[]>>
  setParams: Dispatch<SetStateAction<ProfessionalParams>>
}

const ProfessionalsContext = createContext<ProfessionalContextType>({} as ProfessionalContextType)

export default ProfessionalsContext