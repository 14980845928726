import { useContext } from 'react'

// material-ui
import { Avatar, Button, CardContent, Grid2 as Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import UserCompanyContext from '../../../../context/usercompany/UserCompanyContext'
import { BlueCard, YellowCard } from '../../../../ui-components/extended/card/CardStyles'
import { SubscriptionStatus } from '../../../../types/Subscription'
import { remainingDays } from '../../../../utils/dates'

// ========================|| SIDEBAR MENU CARD ||======================== //

const MenuCard = () => {
  // hooks
  const theme = useTheme()
  const { t } = useTranslation()
  const { userCompany } = useContext(UserCompanyContext)
  const { subscription } = useContext(OptionalSubscriptionContext)

  const freeTrialRemainingDays = remainingDays(subscription?.freeTrialEndDate)

  const NoSubscriptionCard = () => (
    <YellowCard variant='outlined'>
      <CardContent>
        <Grid container direction='column' spacing={2}>
          <Grid>
            <Typography variant='h4'>{t('No active subscription')}</Typography>
          </Grid>
          <Grid>
            <Typography variant='subtitle2' color='text.dark' sx={{ opacity: 0.6 }}>
              {t('Purchase a subscription to have full access.')}
            </Typography>
          </Grid>
          <Grid>
            <Stack direction='row'>
              <Button variant='contained' color='warning' sx={{ boxShadow: 'none' }} href={`/companies/${userCompany.id}/subscription`}>
                {t('Purchase')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </YellowCard>
  )

  const PausedSubscriptionCard = () => (
    <YellowCard variant='outlined'>
      <CardContent>
        <Grid container direction='column' spacing={2}>
          <Grid>
            <Typography variant='h4'>{t('Paused subscription')}</Typography>
          </Grid>
          <Grid>
            <Typography variant='subtitle2' color='text.dark' sx={{ opacity: 0.6 }}>
              {t('Subscription was paused due to payment issues.')}
            </Typography>
          </Grid>
          <Grid>
            <Stack direction='row'>
              <Button variant='contained' color='warning' sx={{ boxShadow: 'none' }} href={`/companies/${userCompany.id}/subscription`}>
                {t('Purchase')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </YellowCard>
  )

  if (!subscription) {
    return <NoSubscriptionCard />
  }

  if (subscription.status === SubscriptionStatus.TRIALING) {
    return (
      <BlueCard variant='outlined'>
        <CardContent sx={{ p: 2, '&:last-child': { pb: '16px' } }}>
          <List sx={{ p: 0, m: 0 }}>
            <ListItem alignItems='flex-start' disableGutters sx={{ p: 0 }}>
              <ListItemAvatar sx={{ mt: 0 }}>
                <Avatar
                  variant='rounded'
                  sx={{
                    ...theme.commonAvatar,
                    ...theme.largeAvatar,
                    mr: '12px',
                    color: theme.palette.primary.main,
                    border: 'none',
                    borderColor: theme.palette.primary.main,
                    background: theme.palette.background.default,
                  }}
                >
                  <TableChartOutlinedIcon fontSize='inherit' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ mt: 0 }}
                primary={
                  <Typography variant='subtitle1' sx={{ color: theme.palette.primary[800] }}>
                    {t('Free Trial')}
                  </Typography>
                }
                secondary={
                  <Typography variant='caption' color='text.secondary' sx={{ color: theme.palette.grey[500] }}>
                    {freeTrialRemainingDays} {freeTrialRemainingDays === 1 ? t('remaining day') : t('remaining days')}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </BlueCard>
    )
  }

  if (subscription.status === SubscriptionStatus.PENDING) {
    return <NoSubscriptionCard />
  }

  if (subscription.status === SubscriptionStatus.PAUSED) {
    return <PausedSubscriptionCard />
  }

  if (subscription.status === SubscriptionStatus.ACTIVE) {
    return (
      <BlueCard variant='outlined'>
        <CardContent sx={{ p: 2, '&:last-child': { pb: '16px' } }}>
          <List sx={{ p: 0, m: 0 }}>
            <ListItem alignItems='flex-start' disableGutters sx={{ p: 0 }}>
              <ListItemAvatar sx={{ mt: 0 }}>
                <Avatar
                  variant='rounded'
                  sx={{
                    ...theme.commonAvatar,
                    ...theme.largeAvatar,
                    mr: '12px',
                    color: theme.palette.primary.main,
                    border: 'none',
                    borderColor: theme.palette.primary.main,
                    background: theme.palette.background.default,
                  }}
                >
                  <TableChartOutlinedIcon fontSize='inherit' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ mt: 0 }}
                primary={
                  <Typography variant='subtitle1' sx={{ color: theme.palette.primary[800] }}>
                    {t('Plan')}
                  </Typography>
                }
                secondary={
                  <Typography variant='caption' color='text.secondary' sx={{ color: theme.palette.grey[500] }}>
                    {subscription.professionals} {subscription.professionals > 1 ? t('professionals') : t('professional')}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </BlueCard>
    )
  }

  return null
}

export default MenuCard
