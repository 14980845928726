import { SyntheticEvent, useContext, useState } from 'react'

// material-ui
import { Tab, Tabs } from '@mui/material'

// third-party
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
// project imports
import UserContext from '../../../../context/user/UserContext'
import BookingsContext from '../../../../context/booking/BookingsContext'
import { BookingStatus } from '../../../../types/Booking'
import { locale } from '../../../../utils/locale'

// ========================|| BOOKING - TABS ||======================== //

export default function BookingTabs() {
  // hooks
  const { i18n, t } = useTranslation()
  const { user } = useContext(UserContext)
  const { setParams } = useContext(BookingsContext)

  // state
  const [value, setValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)

    switch (newValue) {
      case 0:
        upcoming()
        break
      case 1:
        today()
        break
      case 2:
        yesterday()
        break
      case 3:
        all()
        break
    }
  }

  const upcoming = () => setParams(params => ({
    ...params,
    status: [BookingStatus.PENDING, BookingStatus.CONFIRMED],
    professionals: [],
    startDate: dayjs().locale(locale(i18n.language)).tz(user.timezone),
    endDate: undefined,
    page: 0
  }))

  const today = () => setParams(params => ({
    ...params,
    status: [],
    professionals: [],
    startDate: dayjs().locale(locale(i18n.language)).tz(user.timezone).startOf('day'),
    endDate: dayjs().locale(locale(i18n.language)).tz(user.timezone).endOf('day'),
    page: 0,
    sort: 'date,desc'
  }))

  const yesterday = () => setParams(params => ({
    ...params,
    status: [],
    professionals: [],
    startDate: dayjs().subtract(1, 'day').locale(locale(i18n.language)).tz(user.timezone).startOf('day'),
    endDate: dayjs().subtract(1, 'day').locale(locale(i18n.language)).tz(user.timezone).endOf('day'),
    page: 0,
    sort: 'date,desc'
  }))

  const all = () => setParams(params => ({
    ...params,
    status: [],
    professionals: [],
    startDate: undefined,
    endDate: undefined,
    page: 0,
    sort: 'date,desc'
  }))

  return (
    <Tabs value={value} onChange={handleChange} sx={{ '& .MuiTabs-flexContainer': { padding: '0 24px' } }}>
      <Tab label={t('Upcoming')} value={0} />
      <Tab label={t('Today')} value={1} />
      <Tab label={t('Yesterday')} value={2} />
      <Tab label={t('All')} value={3} />
    </Tabs>
  )
}