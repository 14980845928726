import React, { useContext, useState } from 'react'

// material-ui
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel, FormGroup,
  FormHelperText,
} from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import CompanyApi from '../../../../api/company/CompanyApi'
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserCompaniesContext from '../../../../context/usercompany/UserCompaniesContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { UserCompany } from '../../../../types/UserCompany'

// apis
const companyApi = new CompanyApi()

// ========================|| USER COMPANY - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  userCompany: UserCompany
  onClose: () => void
}

export default function UserCompanyDeleteDialog({ userCompany, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(UserStatsContext)
  const { setUserCompanies } = useContext(UserCompaniesContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeCompany = (userCompanyId: string) => {
    setUserCompanies(userCompanies => userCompanies.filter(userCompany => userCompany.id !== userCompanyId))
  }

  const handleSuccess = () => {
    removeCompany(userCompany.id)

    setStats({ ...stats, companies: stats.companies - 1 })

    dispatch(
      setSnackbar({
        message: t('Company deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting company'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      companyApi.delete(token, userCompany.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-company-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-company-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to delete the company') + ' '}
          <strong>{userCompany.displayName}</strong>?
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox id='users' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('If you have an active subscription, it will be canceled')}
            sx={{ cursor: 'text' }}
          />
          <FormControlLabel
            control={<Checkbox id='bookings' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All pending and confirmed bookings will be canceled')}
            sx={{ cursor: 'text' }}
          />
          <FormControlLabel
            control={<Checkbox id='professionals' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('The company cannot be restored')}
            sx={{ cursor: 'text' }}
          />
          <FormHelperText error>{t('This action cannot be undone.')}</FormHelperText>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
