import { useContext, useState } from 'react'

// material-ui
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserContext from '../../../../context/user/UserContext'
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserCompanyCreateDialog from '../UserCompanyCreateDialog/UserCompanyCreateDialog'

// ========================|| USER COMPANY - CREATE BUTTON ||======================== //

export default function UserCompanyCreateButton() {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const { stats } = useContext(UserStatsContext)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tooltipTitle = () => {
    if (user.limits.companies === stats.companies) {
      return 'Companies limit reached. If you need more companies, please contact us.'
    }

    return 'Create Company'
  }

  const isDisabled = user.limits.companies === stats.companies

  return (
    <>
      <Tooltip title={t(tooltipTitle())}>
        <span>
          <Fab
            color='primary'
            aria-label='Create Company'
            sx={{
              width: '32px',
              height: '32px',
              minHeight: '32px',
              boxShadow: 'none',
            }}
            disabled={isDisabled}
            onClick={handleOpen}
          >
            <AddIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <UserCompanyCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
