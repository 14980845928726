import React, { useContext, useState } from 'react'

// material-ui
import { Box, Divider, FormControl, FormControlLabel, FormLabel, Grid2 as Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import BillingApi from '../../../../api/billing/BillingApi'
import BillingContext from '../../../../context/billing/BillingContext'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import { BillingInformation, IdentifierType } from '../../../../types/BillingInformation'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// apis
const billingApi = new BillingApi()

// ========================|| BILLING - FORM ||======================== //

export default function BillingInformationForm() {
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { billing, setBilling } = useContext(BillingContext)
  const dispatch = useDispatch()

  const { control, formState, handleSubmit, reset } = useForm<BillingInformation>({
    defaultValues: {
      name: billing?.name || '',
      identifierType: billing?.identifierType || IdentifierType.DNI,
      identifier: billing?.identifier || '',
      address: billing?.address || '',
    },
  })

  const [loading, setLoading] = useState(false)

  const handleSuccess = (billing: BillingInformation) => {
    setBilling(billing)
    reset(billing)

    dispatch(
      setSnackbar({
        message: t('Billing information updated successfully') as string,
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating billing information'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<BillingInformation> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      billingApi
        .save(token, companyId, form)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Controller
            name='identifierType'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component='fieldset' variant='standard' fullWidth>
                <FormLabel component='legend'>
                  <Typography variant='subtitle1'>{t('Document type')}</Typography>
                </FormLabel>
                <RadioGroup value={value} onChange={onChange} row>
                  <FormControlLabel id='dni' value={IdentifierType.DNI} control={<Radio />} label='DNI' />
                  <FormControlLabel id='cuit' value={IdentifierType.CUIT} control={<Radio />} label='CUIT' disabled />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 4 }}>
          <Controller
            name='identifier'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: { value: 8, message: 'Document number must have 8 characters.' },
              maxLength: { value: 8, message: 'Document number must have 8 characters.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='identifier'
                label={t('Document number')}
                fullWidth
                value={value}
                onChange={(e) => {
                  // Delete any character that is not a number
                  const numericValue = e.target.value.replace(/[^0-9]/g, '');
                  onChange(numericValue);
                }}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 4 }}>
          <Controller
            name='name'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: { value: 2, message: 'Name is too short.' },
              maxLength: { value: 255, message: 'Name is too long.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='name'
                label={t('Name or company name')}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 4 }}>
          <Controller
            name='address'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: { value: 2, message: 'Address is too short.' },
              maxLength: { value: 255, message: 'Address is too long.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='address'
                label={t('Address')}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton variant='contained' type='submit' disabled={!formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
