// material-ui
import { Box, FormControl, FormControlLabel, FormLabel, Grid2 as Grid, Radio, RadioGroup, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import { UserProfessionalRole } from '../../../../types/UserProfessionalRole'

// ========================|| PROFESSIONAL USER - FORM ||======================== //

export default function ProfessionalUserEditForm() {
  // hooks
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='role'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <FormLabel id='role-group-label' focused={false} error={!!error} sx={{ fontWeight: 500, mb: '8px' }}>
                {t('Role')}
              </FormLabel>
              <RadioGroup aria-labelledby='role-group-label'>
                <Grid container spacing={1}>
                  <Grid size={12}>
                    <SubCard content={false} sx={{ p: 1 }}>
                      <FormControlLabel
                        control={<Radio size='small' sx={{ ml: '6px' }} />}
                        value={UserProfessionalRole.EDITOR}
                        checked={value === UserProfessionalRole.EDITOR}
                        onChange={onChange}
                        disableTypography
                        label={
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Typography variant='subtitle1'>{t('Editor')}</Typography>
                            <Typography variant='caption'>{t('Can view and edit the professional bookings')}</Typography>
                          </Box>
                        }
                      />
                    </SubCard>
                  </Grid>
                  <Grid size={12}>
                    <SubCard content={false} sx={{ p: 1 }}>
                      <FormControlLabel
                        control={<Radio size='small' sx={{ ml: '6px' }} />}
                        value={UserProfessionalRole.VIEWER}
                        checked={value === UserProfessionalRole.VIEWER}
                        onChange={onChange}
                        disableTypography
                        label={
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Typography variant='subtitle1'>{t('Viewer')}</Typography>
                            <Typography variant='caption'>{t('Can view the professional bookings')}</Typography>
                          </Box>
                        }
                      />
                    </SubCard>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
