// project imports
import UserProfessionalsContext from '../../../context/userprofessional/UserProfessionalsContext'
import useUserProfessionals from '../../../hooks/userprofessional/useUserProfessionals'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import UserProfessionalCard from './UserProfessionalCard/UserProfessionalCard'

// ========================|| USER PROFESSIONAL ||======================== //

export default function UserProfessionals() {
  const state = useUserProfessionals()

  if (state.firstLoading) {
    return <Loader/>
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <UserProfessionalsContext.Provider value={state}>
      <UserProfessionalCard />
    </UserProfessionalsContext.Provider>
  )
}