// project imports
import { axiosInstance } from '../../axios'
import { DateSet } from '../../types/DateSet'
import { TimeSet } from '../../types/TimeSet'

// ========================|| API - AVAILABILITY ||======================== //

export default class AvailabilityApi {
  getDateSet(token: string, serviceId: string, professionalId: string): Promise<DateSet> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { serviceId, professionalId }
    }

    return axiosInstance.get('/api/v1/availability/dates', config)
  }

  getTimeSet(token: string, serviceId: string, professionalId: string, date: String): Promise<TimeSet> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { serviceId, professionalId, date }
    }

    return axiosInstance.get('/api/v1/availability/times', config)
  }
}
