// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// ========================|| HOOK - COMPANY TOOLTIP ||======================== //

const useTooltip = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      return t('tooltip.company.bookings')
    }
    case 'new': {
      return t('tooltip.company.bookings.new')
    }
    case 'clients': {
      return t('tooltip.company.clients')
    }
    case 'business-hours': {
      return t('tooltip.company.businesshours')
    }
    case 'settings': {
      return t('tooltip.company.settings')
    }
    case 'billing': {
      return t('tooltip.company.billing')
    }
    case 'subscription': {
      return t('tooltip.company.subscription')
    }
    case 'integrations': {
      return t('tooltip.company.integrations')
    }
    case 'branches': {
      return t('tooltip.company.branches')
    }
    case 'professionals': {
      return t('tooltip.company.professionals')
    }
    case 'services': {
      return t('tooltip.company.services')
    }
    case 'users': {
      return t('tooltip.company.users')
    }
    default: {
      return t('tooltip.company')
    }
  }
}

export default useTooltip