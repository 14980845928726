// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BranchUsersContext from '../../../../context/userbranch/BranchUsersContext'
import useBranchId from '../../../../hooks/branch/useBranchId'
import useBranchUsers from '../../../../hooks/userbranch/useBranchUsers'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import Loader from '../../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../../ui-components/extended/alert/ApiErrorAlert'
import BranchUserTable from '../BranchUserTable/BranchUserTable'
import BranchUserCreateButton from '../BranchUserCreateButton/BranchUserCreateButton'

// ========================|| BRANCH - TABS - USERS ||======================== //

export default function BranchUserTab() {
  const { t } = useTranslation()
  const branchId = useBranchId()
  const state = useBranchUsers(branchId)

  if (state.firstLoading) {
    return <Loader />
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <BranchUsersContext.Provider value={state}>
      <SubCard title={t('Administrators')} content={false} secondary={<BranchUserCreateButton />}>
        <BranchUserTable />
      </SubCard>
    </BranchUsersContext.Provider>
  )
}