import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyDeleteDialog from '../UserCompanyDeleteDialog/UserCompanyDeleteDialog'
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - DELETE BUTTON ||======================== //

interface Props {
  userCompany: UserCompany
}

export default function UserCompanyDeleteIconButton({ userCompany }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Delete')}>
        <IconButton aria-label='Delete company' color='error' size='large' onClick={handleOpen}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <UserCompanyDeleteDialog open={open} userCompany={userCompany} onClose={handleClose} />
    </>
  )
}
