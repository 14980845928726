// material-ui
import { Grid2 as Grid, Typography } from '@mui/material'

// project imports
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'
import CompanyUserRoleChip from '../../../CompanyLayout/CompanyUsers/CompanyUserRoleChip/CompanyUserRoleChip'
import UserCompanyDeleteButton from '../UserCompanyDeleteButton/UserCompanyDeleteButton'
import UserCompanyViewButton from '../UserCompanyViewButton/UserCompanyViewButton'
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - LIST ITEM ||======================== //

interface Props {
  userCompany: UserCompany
}

export default function UserCompanyListItem({ userCompany }: Props) {
  return (
    <Grid container spacing={3}>
      <Grid
        size={12}
        display='flex'
        justifyContent='space-between'
        alignItems='center'>
        <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
          <Grid>
            <CompanyAvatar active={userCompany.active} />
          </Grid>
          <Grid>
            <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
              {userCompany.displayName}
            </Typography>
            <Typography variant='caption' align='left' whiteSpace='nowrap'>
              @{userCompany.name}
            </Typography>
          </Grid>
        </Grid>
        <CompanyUserRoleChip role={userCompany.role} />
      </Grid>
      <Grid size={12} container spacing={1}>
        <Grid size={6}>
          <UserCompanyViewButton userCompany={userCompany} />
        </Grid>
        <Grid size={6}>
          <UserCompanyDeleteButton userCompany={userCompany} />
        </Grid>
      </Grid>
    </Grid>
  )
}
