import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ProfessionalApi from '../../api/professional/ProfessionalApi'
import { ApiError } from '../../types/ApiError'
import { Professional } from '../../types/Professional'

// apis
const professionalApi = new ProfessionalApi()

// ========================|| HOOK - PROFESSIONAL ||======================== //

const useProfessional = (professionalId: string) => {
  const { auth } = useAuth()
  const [professional, setProfessional] = useState<Professional>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchProfessional = () => {
    auth!.getIdToken().then(token => {
      professionalApi.findById(token, professionalId)
        .then(data => setProfessional(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchProfessional, [professionalId])

  return { loading, error, professional, setProfessional }
}

export default useProfessional