import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserProfessionalApi from '../../api/userprofessional/UserProfessionalApi'
import { UserProfessional } from '../../types/UserProfessional'
import { ApiError } from '../../types/ApiError'

// apis
const userProfessionalApi = new UserProfessionalApi()

// ========================|| HOOK - USER PROFESSIONAL ||======================== //

export default function useUserProfessional(professionalId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [userProfessional, setUserProfessional] = useState<UserProfessional>()

  const searchUserProfessional = () => {
    auth!.getIdToken().then(token => {
      userProfessionalApi
        .findById(token, professionalId)
        .then(data => setUserProfessional(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchUserProfessional, [professionalId])

  return { loading, error, userProfessional, setUserProfessional }
}
