// project imports
import ProfessionalsProvider from '../../../context/professional/ProfessionalsProvider'
import ProfessionalCard from './ProfessionalCard/ProfessionalCard'

// ========================|| PROFESSIONALS ||======================== //

export default function Professionals() {
  return (
    <ProfessionalsProvider>
      <ProfessionalCard />
    </ProfessionalsProvider>
  )
}
