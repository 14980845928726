// material-ui
import { Box, CssBaseline } from '@mui/material'

// third-party
import { Outlet } from 'react-router-dom'

// project imports
import UserProfessionalContext from '../../context/userprofessional/UserProfessionalContext'
import useProfessionalId from '../../hooks/professional/useProfessionalId'
import useUserProfessional from '../../hooks/userprofessional/useUserProfessional'
import useDrawer from '../../hooks/useDrawer'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import NotFound from '../Maintenance/NotFound/NotFound'
import Error from '../Maintenance/Error/Error'
import CompanyAppBar from '../../layout/AppBar/CompanyAppBar'
import Sidebar from '../../layout/Sidebar/Sidebar'
import Main from '../../layout/Main/Main'
import Menu from './Menu/Menu'

// ========================|| PROFESSIONAL - LAYOUT ||======================== //

export default function ProfessionalLayout() {
  const professionalId = useProfessionalId()
  const { drawerOpened } = useDrawer()
  const { loading, error, userProfessional, setUserProfessional } = useUserProfessional(professionalId)

  if (!professionalId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    if (error.status === 403 || error.status === 404) {
      return <NotFound />
    }

    return <Error />
  }

  if (!userProfessional) {
    return <Error />
  }

  return (
    <UserProfessionalContext.Provider value={{ userProfessional, setUserProfessional }}>
      <Box display='flex'>
        <CssBaseline />

        <CompanyAppBar />

        <Sidebar>
          <Menu />
        </Sidebar>

        <Main open={drawerOpened}>
          <Outlet />
        </Main>
      </Box>
    </UserProfessionalContext.Provider>
  )
}
