// third-party
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// Auth
import { AuthProvider } from '../context/auth/useAuth'

// Guards
import GuestGuard from './route-guard/GuestGuard'
import AuthGuard from './route-guard/AuthGuard'
import NonUserGuard from './route-guard/NonUserGuard'
import UserGuard from './route-guard/UserGuard'

// Landing
import Landing from '../views/Landing/Landing'

// Privacy
import PrivacyPolicy from '../views/PrivacyPolicy/PrivacyPolicy'
import TermsAndConditions from '../views/TermsAndConditions/TermsAndConditions'

// Authentication
import SignIn from '../views/Authentication/SignIn/SignIn'
import SignUp from '../views/Authentication/SignUp/SignUp'

// Maintenance
import NotFound from '../views/Maintenance/NotFound/NotFound'

// Layout
import UserLayout from '../views/UserLayout/UserLayout'
import CompanyLayout from '../views/CompanyLayout/CompanyLayout'

// User
import Companies from '../views/UserLayout/Companies/Companies'
import CompanyBooking from '../views/UserLayout/Company/Company'
import UserBookings from '../views/UserLayout/Bookings/Bookings'
import AccountSettings from '../views/UserLayout/AccountSettings/AccountSettings'

// Company
import UserCompanies from '../views/UserLayout/UserCompanies/UserCompanies'
import CompanyBookings from '../views/CompanyLayout/Bookings/Bookings'
import Company from '../views/CompanyLayout/Company/Company'
import BusinessHours from '../views/CompanyLayout/BusinessHours/BusinessHours'
import Settings from '../views/CompanyLayout/Settings/Settings'
import Profile from '../views/CompanyLayout/Profile/Profile'
import Branches from '../views/CompanyLayout/Branches/Branches'
import Professionals from '../views/CompanyLayout/Professionals/Professionals'
import Services from '../views/CompanyLayout/Services/Services'
import CompanyUsers from '../views/CompanyLayout/CompanyUsers/CompanyUsers'
import Billing from '../views/CompanyLayout/Billing/Billing'
import Subscription from '../views/CompanyLayout/Subscription/Subscription'
import Integration from '../views/CompanyLayout/Integration/Integration'
import ProfessionalTab from '../views/CompanyLayout/Professional/ProfessionalTab/ProfessionalTab'
import Professional from '../views/CompanyLayout/Professional/Professional'
import ProfessionalBusinessHourTab from '../views/CompanyLayout/Professional/ProfessionalBusinessHourTab/ProfessionalBusinessHourTab'
import ProfessionalUserTab from '../views/CompanyLayout/Professional/ProfessionalUserTab/ProfessionalUserTab'
import Branch from '../views/CompanyLayout/Branch/Branch'
import BranchTab from '../views/CompanyLayout/Branch/BranchTab/BranchTab'
import BranchBusinessHoursTab from '../views/CompanyLayout/Branch/BranchBusinessHoursTab/BranchBusinessHoursTab'
import BranchUserTab from '../views/CompanyLayout/Branch/BranchUserTab/BranchUserTab'
import Clients from '../views/CompanyLayout/Clients/Clients'
import Service from '../views/CompanyLayout/Service/Service'
import ServiceTab from '../views/CompanyLayout/Service/ServiceTab/ServiceTab'
import ServiceProfessionalTab from '../views/CompanyLayout/Service/ServiceProfessionalTab/ServiceProfessionalTab'
import BookingNew from '../views/CompanyLayout/BookingNew/BookingNew'
import UserBranches from '../views/UserLayout/UserBranches/UserBranches'
import UserProfessionals from '../views/UserLayout/UserProfessionals/UserProfessionals'

// Branch
import BranchLayout from '../views/BranchLayout/BranchLayout'
import BranchBookings from '../views/BranchLayout/Bookings/Bookings'

// Professional
import ProfessionalLayout from '../views/ProfessionalLayout/ProfessionalLayout'
import ProfessionalBookings from '../views/ProfessionalLayout/Bookings/Bookings'
import OwnerGuard from './route-guard/OwnerGuard'

// ========================|| APP ROUTING ||======================== //

export default function ThemeRoute() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<GuestGuard />}>
            <Route index element={<Landing />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/terms' element={<TermsAndConditions />} />
          </Route>

          <Route element={<AuthGuard />}>
            <Route element={<NonUserGuard />}>
              <Route path='/signup' element={<SignUp />} />
            </Route>

            <Route element={<UserGuard />}>
              <Route element={<UserLayout />}>
                <Route path='/companies' element={<Companies />} />
                <Route path='/users/me/bookings' element={<UserBookings />} />
                <Route path='/users/me/companies' element={<UserCompanies />} />
                <Route path='/users/me/branches' element={<UserBranches />} />
                <Route path='/users/me/professionals' element={<UserProfessionals />} />
                <Route path='/users/me' element={<AccountSettings />} />
                <Route path='/:companyName' element={<CompanyBooking />} />
              </Route>

              <Route path='/companies/:companyId' element={<CompanyLayout />}>
                <Route element={<Company />}>
                  <Route index element={<Profile />} />
                  <Route path='business-hours' element={<BusinessHours />} />
                  <Route path='settings' element={<Settings />} />
                  <Route element={<OwnerGuard />}>
                    <Route path='subscription' element={<Subscription />} />
                    <Route path='billing' element={<Billing />} />
                    <Route path='integrations' element={<Integration />} />
                  </Route>
                </Route>

                <Route path='clients' element={<Clients />} />
                <Route path='bookings' element={<CompanyBookings />} />
                <Route path='bookings/new' element={<BookingNew />} />
                <Route path='branches' element={<Branches />} />
                <Route path='professionals' element={<Professionals />} />
                <Route path='services' element={<Services />} />
                <Route path='users' element={<CompanyUsers />} />

                <Route element={<Branch />}>
                  <Route path='branches/:branchId' element={<BranchTab />} />
                  <Route path='branches/:branchId/business-hours' element={<BranchBusinessHoursTab />} />
                  <Route path='branches/:branchId/users' element={<BranchUserTab />} />
                </Route>

                <Route element={<Professional />}>
                  <Route path='professionals/:professionalId' element={<ProfessionalTab />} />
                  <Route path='professionals/:professionalId/business-hours' element={<ProfessionalBusinessHourTab />} />
                  <Route path='professionals/:professionalId/users' element={<ProfessionalUserTab />} />
                </Route>

                <Route element={<Service />}>
                  <Route path='services/:serviceId' element={<ServiceTab />} />
                  <Route path='services/:serviceId/professionals' element={<ServiceProfessionalTab />} />
                </Route>
              </Route>

              <Route path='/branches/:branchId' element={<BranchLayout />}>
                <Route path='bookings' element={<BranchBookings />} />
              </Route>

              <Route path='/professionals/:professionalId' element={<ProfessionalLayout />}>
                <Route path='bookings' element={<ProfessionalBookings />} />
              </Route>
            </Route>
          </Route>
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}
