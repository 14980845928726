import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { CompanyUser } from '../../types/CompanyUser'
import { UserCompanyParams } from '../../types/UserCompany'

// ========================|| CONTEXT - PROFESSIONAL ||======================== //

interface CompanyUsersContextType {
  loading: boolean
  companyUsers: CompanyUser[]
  params: UserCompanyParams
  setCompanyUsers: Dispatch<SetStateAction<CompanyUser[]>>
  setParams: Dispatch<SetStateAction<UserCompanyParams>>
}

const CompanyUsersContext = createContext<CompanyUsersContextType>({} as CompanyUsersContextType)

export default CompanyUsersContext
