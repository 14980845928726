import { Searchable } from './Page'
import { ProfessionalDetails } from './Professional'

export enum PriceType {
  FIXED = 'FIXED',
  TBD = 'TBD',
}

export interface Service {
  id: string
  name: string
  description: string
  price: number | null
  currency: string | null
  customDepositPercentage: boolean
  depositRequired: boolean
  depositPercentage: number
  duration: number
  buffer: number
  frequency: number
  professionals: ProfessionalDetails[]
}

export interface ServiceDetails {
  id: string
  name: string
  description: string
  price: number | null
  currency: string | null
  customDepositPercentage: boolean
  depositRequired: boolean
  depositPercentage: number
  duration: number
  buffer: number
  frequency: number
}

export interface ServiveForm {
  name: string
  description: string
  priceType: PriceType
  price: string | null
  currency: string | null
  customDepositPercentage: boolean
  depositPercentage: string
  duration: string
  buffer: string
  frequency: string
  professionals: ProfessionalDetails[]
}

export interface ServiceParams extends Searchable {}
