import React, { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import UserBranchApi from '../../../../api/userbranch/UserBranchApi'
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserBranchesContext from '../../../../context/userbranch/UserBranchesContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { UserBranch } from '../../../../types/UserBranch'

// apis
const userBranchApi = new UserBranchApi()

// ========================|| USER BRANCH - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  userBranch: UserBranch
  onClose: () => void
}

export default function UserBranchDeleteDialog({ userBranch, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(UserStatsContext)
  const { setUserBranches } = useContext(UserBranchesContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeBranch = (userBranchId: string) => {
    setUserBranches(userBranches => ({ ...userBranches, content: userBranches.content.filter(userBranch => userBranch.id !== userBranchId) }))
  }

  const reduceBranchesStat = () => {
    setStats({ ...stats, branches: stats.branches - 1 })
  }

  const handleSuccess = () => {
    removeBranch(userBranch.id)
    reduceBranchesStat()

    dispatch(
      setSnackbar({
        message: t('User unlinked successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while unlinking user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      userBranchApi.delete(token, userBranch.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-userbranch-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-userbranch-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to be unlinked from the branch') + ' '}
          <strong>{userBranch.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Unlink')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
