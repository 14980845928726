import { Client } from '../types/Client'
import { ServiceDetails } from '../types/Service'
import { ServiceProfessional } from '../types/ServiceProfessional'
import { Company } from '../types/Company'

export const resolvePrice = (service: ServiceDetails, professional: ServiceProfessional): number | null => {
  return professional.price !== null ? professional.price : service.price;
}

export const resolveCurrency = (service: ServiceDetails, professional: ServiceProfessional): string | null => {
  return professional.currency || service.currency
}

export const getDepositPercentage = (service: ServiceDetails, company: Company) => {
  return service.customDepositPercentage ? service.depositPercentage : company.settings.depositPercentage
}

export const isDepositRequired = (client: Client | undefined, service: ServiceDetails, professional: ServiceProfessional): boolean => {
  const price = resolvePrice(service, professional)
  return service.depositRequired && price !== null && price > 0 && (client ? client.depositEnabled : true)
}