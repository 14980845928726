// project imports
import useProfessionalId from '../../../hooks/professional/useProfessionalId'
import MenuGroup from '../../../layout/Menu/MenuGroup'
import MenuItem from '../../../layout/Menu/MenuItem'

// assets
import { IconCalendarTime } from '@tabler/icons-react'

// ========================|| LAYOUT - MENU ||======================== //

export default function Menu() {
  const professionalId = useProfessionalId()

  return (
    <>
      <MenuGroup title='Admin'>
        <MenuItem title='Bookings' url={`/professionals/${professionalId}/bookings`} icon={IconCalendarTime} />
      </MenuGroup>
    </>
  )
}
