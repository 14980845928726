import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyProfessionalApi from '../../api/professional/CompanyProfessionalApi'
import { Professional, ProfessionalParams } from '../../types/Professional'
import { ApiError } from '../../types/ApiError'

// apis
const professionalApi = new CompanyProfessionalApi()

// ========================|| HOOK - COMPANY PROFESSIONALS ||======================== //

export default function useProfessionals(companyId: string) {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [professionals, setProfessionals] = useState<Professional[]>([])
  const [params, setParams] = useState<ProfessionalParams>({})

  useEffect(() => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      professionalApi
        .findAll(token, companyId, params)
        .then(data => setProfessionals(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }, [companyId, params])

  return { firstLoading, loading, error, professionals, params, setProfessionals, setParams }
}
