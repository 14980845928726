import { useEffect } from 'react'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import ProfessionalContext from '../../../context/professional/ProfessionalContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useProfessionalId from '../../../hooks/professional/useProfessionalId'
import useProfessional from '../../../hooks/professional/useProfessional'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import ProfessionalCard from './ProfessionalCard/ProfessionalCard'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| PROFESSIONAL ||======================== //

export default function Professional() {
  const companyId = useCompanyId()
  const professionalId = useProfessionalId()
  const dispatch = useDispatch()
  const { loading, error, professional, setProfessional } = useProfessional(professionalId)

  useEffect(() => {
    dispatch(setIsOpen(`/companies/${companyId}/professionals`))
  }, [])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!professional) {
    return <ApiErrorAlert />
  }

  return (
    <ProfessionalContext.Provider value={{ professional, setProfessional }}>
      <ProfessionalCard />
    </ProfessionalContext.Provider>
  )
}
