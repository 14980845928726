import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ClientEditDialog from '../ClientEditDialog/ClientEditDialog'
import { Client } from '../../../../types/Client'

// ========================|| CLIENT - EDIT ICON BUTTON ||======================== //

interface Props {
  client: Client
}

export default function ClientEditIconButton({ client }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t('Edit')}>
        <IconButton aria-label='Edit client' color='secondary' size='large' onClick={handleOpen}>
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <ClientEditDialog open={open} client={client} onClose={handleClose}/>
    </>
  )
}
