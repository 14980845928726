// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { UserProfessional, UserProfessionalParams } from '../../types/UserProfessional'

// ========================|| API - USER PROFESSIONAL ||======================== //

export default class UserProfessionalApi {
  findById(token: string, professionalId: string): Promise<UserProfessional> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/users/me/professionals/${professionalId}`, config)
  }

  findAll(token: string, params: UserProfessionalParams): Promise<Page<UserProfessional>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    }

    return axiosInstance.get('/api/v1/users/me/professionals', config)
  }

  delete(token: string, professionalId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/users/me/professionals/${professionalId}`, config)
  }
}
