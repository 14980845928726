import React, { useContext, useState } from 'react'

// material-ui
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import ProfessionalsContext from '../../../../context/professional/ProfessionalsContext'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import ProfessionalApi from '../../../../api/professional/ProfessionalApi'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Professional } from '../../../../types/Professional'

// apis
const professionalApi = new ProfessionalApi()

// ========================|| PROFESSIONAL - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  professional: Professional
  onClose: () => void
}

export default function ProfessionalDeleteDialog({ professional, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(CompanyStatsContext)
  const { setProfessionals } = useContext(ProfessionalsContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeProfessional = (professionalId: string) => {
    setProfessionals(professionals => professionals.filter(professional => professional.id !== professionalId))
    setStats({ ...stats, professionals: stats.professionals - 1 })
  }

  const handleSuccess = () => {
    removeProfessional(professional.id)

    dispatch(
      setSnackbar({
        message: t('Professional deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting professional'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      professionalApi.delete(token, professional.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-professional-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-professional-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to delete the professional') + ' '}
          <strong>{professional.name}</strong>?
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox id='users' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All associated users will be deleted')}
            sx={{ cursor: 'text' }}
          />
          <FormControlLabel
            control={<Checkbox id='bookings' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All pending and confirmed bookings will be canceled')}
            sx={{ cursor: 'text' }}
          />
          <FormHelperText error>{t('This action cannot be undone.')}</FormHelperText>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
