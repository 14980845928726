import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { ServiceDetails } from '../../../../types/Service'
import ServiceEditDialog from '../ServiceDeleteDialog/ServiceDeleteDialog'

// ========================|| SERVICE - DELETE BUTTON ||======================== //

interface Props {
  service: ServiceDetails
}

export default function ServiceDeleteIconButton({ service }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Delete')}>
        <IconButton aria-label='Delete service' color='error' size='large' onClick={handleOpen}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <ServiceEditDialog open={open} service={service} onClose={handleClose} />
    </>
  )
}
