// project imports
import { axiosInstance } from '../../axios'
import { Professional, ProfessionalParams } from '../../types/Professional'
import { ProfessionalCreateDto } from './dto/ProfessionalCreateDto'

// ========================|| API - COMPANY PROFESSIONAL ||======================== //

export default class ProfessionalApi {
  findAll(token: string, companyId: string, params: ProfessionalParams): Promise<Professional[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/professionals`, config)
  }

  create(token: string, companyId: string, professional: ProfessionalCreateDto): Promise<Professional> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/professionals`, professional, config)
  }
}
