import React, { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import UserCompanyApi from '../../../../api/usercompany/UserCompanyApi'
import UserCompaniesContext from '../../../../context/usercompany/UserCompaniesContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { UserCompany } from '../../../../types/UserCompany'

// apis
const userCompanyApi = new UserCompanyApi()

// ========================|| USER COMPANY - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  userCompany: UserCompany
  onClose: () => void
}

export default function UserCompanyUnlinkDialog({ userCompany, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setUserCompanies } = useContext(UserCompaniesContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeCompany = (userCompanyId: string) => {
    setUserCompanies(userCompanies => userCompanies.filter(userCompany => userCompany.id !== userCompanyId))
  }

  const handleSuccess = () => {
    removeCompany(userCompany.id)

    dispatch(
      setSnackbar({
        message: t('User unlinked successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while unlinking user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      userCompanyApi.delete(token, userCompany.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-usercompany-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-usercompany-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to be unlinked from the company') + ' '}
          <strong>{userCompany.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Unlink')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
