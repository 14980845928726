import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ProfessionalUserApi from '../../api/userprofessional/ProfessionalUserApi'
import { DEFAULT_PAGE, DEFAULT_PARAMS, Page } from '../../types/Page'
import { ApiError } from '../../types/ApiError'
import { UserProfessionalParams } from '../../types/UserProfessional'
import { ProfessionalUser } from '../../types/ProfessionalUser'

// apis
const professionalUserApi = new ProfessionalUserApi()

// ========================|| HOOK - PROFESSIONAL USERS ||======================== //

const useProfessionalUsers = (professionalId: string) => {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [professionalUsers, setProfessionalUsers] = useState<Page<ProfessionalUser>>(DEFAULT_PAGE)
  const [params, setParams] = useState<UserProfessionalParams>(DEFAULT_PARAMS)

  const searchProfessionalUsers = () => {
    if (professionalId) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        professionalUserApi
          .findAll(token, professionalId, params)
          .then(data => setProfessionalUsers(data))
          .catch(error => setError(error))
          .finally(() => {
            setFirstLoading(false)
            setLoading(false)
          })
      })
    } else {
      setFirstLoading(false)
      setLoading(false)
    }
  }

  useEffect(searchProfessionalUsers, [professionalId])

  return { firstLoading, loading, error, professionalUsers, params, setProfessionalUsers, setParams }
}

export default useProfessionalUsers
